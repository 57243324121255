<div class="col-sm-12 p-0 mt-0">
  <div class="col-sm-12 title-wrap d-flex py-1 mb-1 pr-3 pl-2" >
    <div class="col-sm-12 header-wrap p-0" style="margin-left: 12px; width: 98%;">
      <ng-container *ngFor="let header of templateList[0].headers; let i = index;">
        <span [ngClass]="header.colWidth" [style]="header.colStyle">
          <div style="color:black !important; margin-left: 10px;">
            {{ header.Name }}
          </div>
        </span>
      </ng-container>
    </div>
  </div>
<div>
  <div class="header-wrap pr-0">

    <div class="col-sm-12 pl-0 pr-1 table-scroll">
      <app-shimmerui *ngIf="showloader" [line]="8"></app-shimmerui>

      <div class="col-sm-12 nodata-header-wrap"
           *ngIf="filteredReports.length === 0 && !searchText && !showloader">
        <span class="col-sm-12 nodata-wrapper">No Data Available</span>
      </div>
      <div class="col-sm-12 nodata-header-wrap"
           *ngIf="filteredReports.length === 0 && searchText && !showloader">
        <span class="col-sm-12 nodata-wrapper">No Results</span>
      </div>
      <span *ngIf="filteredReports.length > 0">
        <div *ngFor="let report of filteredReports; let i = index;"
        style="margin-left: 10px; width: 98%;"
             [ngClass]="report.published ? 'row grid-data mt-1 ml-0 mr-3 p-2 mb-1 cursor'
             : 'row grid-data mt-1 ml-0 mr-3 p-2 mb-1 inactive-class cursor'">
          <div *ngFor="let header of templateList[0].headers"
               [ngClass]="header.colWidth"
               matTooltip="{{ header.key === 'reportName' ? displayedColumns(report, header.key) : '' }}"
               (mouseenter)="onMouseEnter($event, report, header)">
            <div *ngIf="header.key === 'reportName'">

              <!-- <span *ngIf="report[header.key].length>40" class="truncated-text report-link"
              (click)="RedirectToView(report)">
              {{ displayedColumns(report, header.key).slice(0,50)+"...." }}
            </span> -->
            <!-- *ngIf="report[header.key].length<=40" -->
              <span   class="truncated-text report-link"
                    (click)="RedirectToView(report)">
                {{ displayedColumns(report, header.key) }}
              </span>
            </div>

            <div *ngIf="header.key === 'published'" style="text-align: center; padding-left: 15px;">
              <ng-container *ngIf="report.published; else draft">
                <!-- <img src="/assets/Icons/Published - Green.svg" alt="Published"  matTooltip="Published"> -->
                <button  matTooltip="Published" style="width: 18px;height: 12px;border: none;background-color: green;border-radius: 10px;"></button>
              </ng-container>
              <ng-template #draft>
                <!-- <img src="/assets/Icons/Draft - Grey.svg" alt="Draft"  matTooltip="Draft"> -->
                <button  matTooltip="Draft" style="width: 18px;height: 12px;border: none;background-color: gray;border-radius: 10px;"></button>

              </ng-template>
            </div>
            <div *ngIf="header.key === 'createdDate'" style="text-align: center; padding-left: 15px;">
              {{ displayedColumns(report, header.key) }}
            </div>
            <div *ngIf="header.key === 'menu'" style="padding-left: 3px;">
              {{ displayedColumns(report, header.key) }}
            </div>
            <div *ngIf="header.key === 'schedules'" style="padding-left: 5px;">
              {{ displayedColumns(report, header.key) }}
            </div>
            <div *ngIf="header.key === 'reportType'" style="text-align: center; padding-left: 15px;">
              {{ displayedColumns(report, header.key) }}
            </div>
          </div>
        </div>
      </span>
    </div>
  </div>
</div>
</div>

