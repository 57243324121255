import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RbDataService } from 'src/app/services/rb-data.service';

@Component({
  selector: 'app-report-categories',
  templateUrl: './report-categories.component.html',
  styleUrls: ['./report-categories.component.scss']
})
export class ReportCategoriesComponent implements OnInit {

  constructor(private rdb: RbDataService) { }

  @Input() selected: string = '';
  @Output() selectedChange = new EventEmitter<string>();

  _categories: string[] = [];

  ngOnInit(): void {
    this.rdb.GetReportCategories().then(res => {
      this._categories = res.data;
      // Emit initial value to ensure it gets propagated to parent if necessary
      if (this.selected) {
        this.selectedChange.emit(this.selected);
      }
    });
  }

  selectCategory(category: string): void {
    this.selected = category;
    this.selectedChange.emit(this.selected);
  }
}
