<div class="menu-header">
  <span class="label-header">
    Select Category
    <label class="text-danger fw-bold fs-6">*</label>
  </span>
</div>

<div class="popup-overlay" *ngIf="showPopup"></div>

<div class="popup" *ngIf="showPopup">
  <div class="popup-content">
    <h3>Add Custom Category</h3>
    <input
      type="text"
      [(ngModel)]="customCategory"
      placeholder="Enter category name"
      class="popup-input"
    />
    <div class="popup-actions">
      <button class="btn-cancel" (click)="closePopup()">Cancel</button>
      <button class="btn-add" (click)="addCustomCategory()">Add</button>
    </div>
  </div>
</div>

<div class="row-container">
  <div class="menu-container">
    <div class="single-select-menu">
      <!-- Conditionally render the Add button -->
      <button
        *ngIf="isAdd"
        class="add-category-btn right-corner"
        (click)="togglePopup()"
      >
        +
      </button>
      <div
        *ngFor="let category of _categories"
        (click)="selectCategory(category)"
        [class.selected]="category === selected"
        class="menu-item"
      >
        {{ category }}
      </div>
    </div>
  </div>
</div>
