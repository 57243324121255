
<span class="text-muted">{{header}}</span>
<div [class]="cssStyle + ' d-flex flex-column align-items-center border border-secondary w-100 fix-overflow border-radius-for-header'">

    <div class="w-100 p-2 bg-white label-colors">
        <ng-content></ng-content>
    </div>
</div>



    <!-- <div class="p-2  w-100  bg-primary-dark text-blac">
        {{header}}
    </div> -->