import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-time-picker-control',
  templateUrl: './time-picker-control.component.html',
  styleUrls: ['./time-picker-control.component.scss']
})
export class TimePickerControlComponent implements OnInit {

  
  @Input() dateTime :Date = new Date();
  @Output() dateTimeChange  = new EventEmitter<Date>();

  constructor() { }

  ngOnInit(): void {
  }


}
