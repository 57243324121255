import { Injectable } from "@angular/core";
import { RBCustomReportsDefinition, RBOrganizationReportsDefinition, RBUserscheduledReportMaster } from "../helpers/app-models";
import { AxioHelper } from "../helpers/axios-helper";
import { ConfigUtils } from "../helpers/config-utils";
import { MainAppUtils } from "../helpers/main-app-utils"; 
import { Observable, from, defer, throwError } from 'rxjs';
import { AxiosResponse } from 'axios';
import { retryWhen, catchError, delay, mergeMap } from 'rxjs/operators';
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RbDataService {
  constructor(private ax: AxioHelper, private config: ConfigUtils) { }

  UpdateSlaveReport(report: RBOrganizationReportsDefinition) {
    return this.ax
      .getAxiosWithHeaders()
      .post(this.config.BuildApiUrl("UpdateSlaveReport"), report, {withCredentials:true});
  }

  UpdateSchedules(schedules: RBUserscheduledReportMaster[]) {
    return this.ax
      .getAxiosWithHeaders()
      .post(this.config.BuildApiUrl("UpdateScheduledJobs"), schedules, {withCredentials:true});
  }

  RemoveScheduledJobs(jobIdCsv: string) {
    return this.ax
      .getAxiosWithHeaders()
      .post(this.config.BuildApiUrl("RemoveScheduledJobs"), null, { params: { JobIdsCsv: jobIdCsv } });
  }

  public DownloadTemplate(fileName: string) {
    const params = new URLSearchParams({ Templatefile: fileName });

    return this.ax.getAxiosWithHeaders()({
      url: `${this.config.BuildApiUrl("DownloadExcelTemplate")}?${params}`,
      method: 'GET',
      responseType: 'blob', // important
      withCredentials: true
    });
  }

  public UploadTemplate(fileToUpload: File) {
    let formData = new FormData();
    formData.append("File", fileToUpload, fileToUpload.name);
    //formData.append("DeploymentKey", this.config.GetDeploymentKey());    
    console.log(formData.get("File"));
    
    return this.ax
      .getAxiosWithHeadersForXLUpload()
      .post(
        `${this.config.BuildApiUrl("UploadExcelTemplate")}`,
        formData
        ,{ withCredentials : true });
  }

  GetAllMasterSlaveReports(){
    return this.ax
    .getAxiosWithHeaders()
    .get(this.config.BuildApiUrl("GetMasterReports"), { 
      withCredentials : true});
  }

  public FetchUsers(sampleText:string){
    return this.ax
      .getAxiosWithHeaders()
      .get(this.config.BuildApiUrl("GetAccountUsers"),{
        params: {
          sampleText: sampleText 
        },
       withCredentials:true,         
      });
  }


  GetSlaveReportsByMasterId(masterId: number,operator:number) {
    return this.ax
      .getAxiosWithHeaders()
      .get(this.config.BuildApiUrl("GetSlaveReportsByOrgID"), {
        params: {
          MasterReportID: masterId,
          OperatorID : operator
        },
       withCredentials:true,         
      });
  }

  GetReportCategories() {
    return this.ax
      .getAxiosWithHeaders()
      .get(this.config.BuildApiUrl("GetAllCategory"));
  }

  GetAllScheduledJobs(ReportId: string,action:number) {
    return this.ax
      .getAxiosWithHeaders()
      .get(this.config.BuildApiUrl("GetAllScheduledJobs"), {
        withCredentials : true,
        params: {ReportId,
          action
        },
      });
  }

  GetAllUserRoles() {
    return this.ax
      .getAxiosWithHeaders()
      .get(this.config.BuildApiUrl('GetAllRoleNames'), {
        withCredentials:true,
        params: {}});
  }

  //--- To Used before saving to DB ---- START ----//
  BuildDatesStoreForScheduler(report:RBOrganizationReportsDefinition, schedules:RBUserscheduledReportMaster[]){
    for(let i = 0; i < schedules.length; i++){
      let sch = schedules[i];
      sch.action = report.action;
      sch.jobName = 'Schedule - '+(i+1);;
      sch.reportId = report.id;
      sch.reportName = report.title;
      sch.fileName = report.templateFile;
      //sch.userId = +this.config.GetUserId();
      sch.status = sch.jobId > 0 ? 'Update' :'New';
      sch.connectionName = report.connectionName;
      sch.deploymentKey =""; //this.config.GetDeploymentKey();
      sch.userEmail = ""; //this.config.GetLoggedUserEmail();
      sch.NotifyBlankAttachment = report.NotifyBlankAttachment;
      sch.notifyOnlyOnData = report.notifyOnlyOnData;

      if(sch && sch.reportFilterCriteria){
        let config = sch.reportFilterCriteria;
        if(config && config.payload){
          config.payload.dates = new Array<any>();
          this.convertDatePropsToDatesArray(config.payload);
          sch.reportFilterCriteria = JSON.stringify(config);
        }
      }
    }
  }

  private convertDatePropsToDatesArray(payload:any){
    let keys = Object.keys(payload);
    let utils = new MainAppUtils();
    for(let i = 0; i < keys.length; i++){
      let key = keys[i];
      if(utils.isDateFilter(key)){
        payload.dates.push(payload[key]);
        delete payload[key];
      }
    }
  }
  //--- To Used before saving to DB ---- END ----//



  //--- To Used when data recieved from DB to show on UI ---- START ----//
  BuildDatesStoreForUI(schedules:RBUserscheduledReportMaster[]){
    for(let i = 0; i < schedules.length; i++){
      let sch = schedules[i];
      if(sch && sch.reportFilterCriteria){
        let config = JSON.parse(sch.reportFilterCriteria);
        if(config && config.payload){
          this.convertDatesArrayToProps(config.payload);
          sch.reportFilterCriteria = config;
        }
      }
    }
    return schedules;
  }
  private convertDatesArrayToProps(payload:any){
    for(let i = 0; i < payload.dates.length; i++){
      let dateConfig = payload.dates[i];
      payload[dateConfig.dateTypeCriteria] = dateConfig;
    }
    delete payload.dates;
  }
  //--- To Used when data recieved from DB to show on UI ---- END ----//

  UpdateReportStatus(ReportId:number){
    return this.ax
    .getAxiosWithHeaders()
    .get(this.config.BuildApiUrl("UpdateReportStatus"), {
      params:{ 
        ReportId: ReportId
      }
    });
  }



  UpdateScheduleStatus(JobId:number,isActive: boolean){
    return this.ax
    .getAxiosWithHeaders()
    .get(this.config.BuildApiUrl("UpdateScheduleStatus"), {
      params:{ 
        JobId: JobId,
        isActive:isActive,
      }
    });
  }

  DeleteScheduledJobs(JobID:number,Operation:number){
    return this.ax
    .getAxiosWithHeaders()
    .get(this.config.BuildApiUrl("DeleteScheduledJobs"),{
      params:{
        JobID:JobID,
        Operation:Operation
      }   
    });
  }

  ValidateReportTitle(Title:string,ReportId:number){
    return this.ax
    .getAxiosWithHeaders()
    .get(this.config.BuildApiUrl("ValidateReportTitle"),{
      withCredentials:true,
      params:{
        Title:Title,
        ReportId:ReportId,
      }   
    });
  }

  PublishReport(ReportID: number) {
    return this.ax
      .getAxiosWithHeaders()
      .get(this.config.BuildApiUrl("PublishReport"), {
        params: {
          ReportID: ReportID 
        },
       withCredentials:true,         
      });
  }

  GetTimezones() {
    return this.ax
      .getAxiosWithHeaders()
      .get(this.config.BuildApiUrl("GetAllTimezones"));
  }
/////new

  GetAllReports(){
    return this.ax
    .getAxiosWithHeaders()
    .get(this.config.BuildApiUrl("GetAllReports"), {
      withCredentials:true,  
    });
  }


  GetReportDetails(reportID:string,Publish:boolean){
    return this.ax
    .getAxiosWithHeaders()
    .get(this.config.BuildApiUrl("ReportDetails"), {
      params: {
        ReportId: reportID ,
        Publish:Publish
      },
      withCredentials:true,  
    });
  }

  UpdateReportConfiguration(id:string,Oper:number,isPublish:boolean,isActive: boolean){
    return this.ax
    .getAxiosWithHeaders()
    .get(this.config.BuildApiUrl("UpdateReportConfiguration"), {
      params:{ 
        ID: id,
        Operation:Oper,
        isPublish:isPublish,
        isActive:isActive,
      },
      withCredentials:true,  
    });
  }

  LoadRequiredMasterDataForFilters(
    reportId: number,
    reportTitle: string,
    criterias: string,
    isAsync: boolean,
    repotCategory: string,
    action:number
  ) {
    return this.ax
      .getAxiosWithHeaders()
      .get(this.config.BuildApiUrl("LoadRequiredDataForFiltering"),
        {
          withCredentials:true,
          params: {
            ReportID: reportId, 
            Criteria: criterias,
            Title: reportTitle,
            IsAsynchronousReport: isAsync, 
            Category: repotCategory,
            action:action
          },
        }
      );
  }

  SamplePreview(jobID: string, ReportID: string, Mastercolumn:string) {
    return this.ax
      .getAxiosWithHeaders()
      .get(this.config.BuildApiUrl("SampleReport"), {
        withCredentials: true,
        params: {
          JobID: jobID,
          ReportId: ReportID,
          Columndetails :Mastercolumn
        }
      });
  }  

  GetReportCriterias(){
    return this.ax
    .getAxiosWithHeaders()
    .get(this.config.BuildApiUrl("GetAllCriterias"));
  }

  UpdateCustomReport(report: RBCustomReportsDefinition) {
    return this.ax
      .getAxiosWithHeaders()
      .post(
        this.config.BuildApiUrl("UpdateCustomReport"), 
        report, 
        {
          withCredentials: true 
        }
      );
  }
}



