import { Component, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { RBUserscheduledReportMaster } from 'src/app/helpers/app-models';
import { MainAppUtils } from 'src/app/helpers/main-app-utils';

@Component({
  selector: 'app-email-config',
  templateUrl: './email-config.component.html',
  styleUrls: ['./email-config.component.scss']
})
export class EmailConfigComponent {

  loader:true;
  labUserForm: FormGroup;
  _showCC: boolean = false;
  _showBCC: boolean = false;
  _mainUtils = new MainAppUtils();
  _selectedSchdule = new RBUserscheduledReportMaster();
  value="PDX_{MMDDYY}"

  EmailSubject = new FormControl();
  EmailBody = new FormControl();
  CustomPassword = new FormControl()
  selectedType: string="dynamic"

  constructor(private _fb: FormBuilder,private activeroute: ActivatedRoute,) {
    this.labUserForm = this._fb.group({
      emailId: ['', [Validators.pattern(/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/)]],
      subject: ['',[Validators.required, Validators.minLength(1)]],
      description: ['',[Validators.required, Validators.minLength(1)]],
      cc:[''],
      bcc:[Validators.required, Validators.minLength(1)],
      password:[]
    });
  }
  ngOnInit(): void {
    this.EmailBody.patchValue(this._selectedSchdule.emailBody)
    this.EmailSubject.patchValue(this._selectedSchdule.emailSubject)
    this.selectedType=this._selectedSchdule.reportLockPasswordType
    this.CustomPassword.patchValue(this._selectedSchdule.reportLockPasswordData)
    this.activeroute.queryParams.subscribe(params => {
      var action = +atob(params['action']);
      if(action!=1 && action!=2){
        this.selectedType="dynamic"
      }
    })
  }

  @Input() set selectedSchdule(val:RBUserscheduledReportMaster){
    this._selectedSchdule = val;
    this._showBCC = !this._mainUtils.isEmptyOrSpaces(this._selectedSchdule.emailBCC)
    this._showCC = !this._mainUtils.isEmptyOrSpaces(this._selectedSchdule.emailBCC)

    this.labUserForm.patchValue({emailId:this._selectedSchdule.emailBCC})
    this.labUserForm.patchValue({reportDescr:this._selectedSchdule.emailBCC})
    this.labUserForm.patchValue({subject:this._selectedSchdule.emailSubject})
    this.labUserForm.patchValue({reportDescr:this._selectedSchdule.emailBCC})
  }

  selectType(type: string) {
    this.selectedType = type;
    this._selectedSchdule.reportLockPasswordType=type
  }

  showCC() {
    this._showCC = !this._showCC;
  }

  showBCC() {
    this._showBCC = !this._showBCC;
  }

  PatchSubject(val:any)
  {
    this._selectedSchdule.emailSubject=val
  }
  PatchBody(val:any)
  {
    this._selectedSchdule.emailBody=val
  }
  PatchPassword(val:any)
  {
    if(val=="default")
    {
      this._selectedSchdule.reportLockPasswordData="PDX_{MMDDYY}"
    }else if(val=="dynamic")
      {
        this._selectedSchdule.reportLockPasswordData=""
      }else {
        this._selectedSchdule.reportLockPasswordData=val
    }
  }
  Enablesentemail()
  {
    this._selectedSchdule.enablePasswordToEmail!=this._selectedSchdule.enablePasswordToEmail
  }


}
