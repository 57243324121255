<!-- <div
  class="d-flex flex-row gap-1 align-items-center justify-content-start cursor-pointer m-0 p-0"
  (click)="onClicked()"
>
  <div>
    <app-bootstrap-icon
      [IconName]="checked ? 'toggle-on' : 'toggle-off'"
      [cssStyle]="cssStyle"
      [IconSize]="size"
    ></app-bootstrap-icon>
  </div>
  <div *ngIf="label" [class]="cssStyle">
    {{label}}
  </div>
</div> -->


<div class="switch-container" [ngClass]="cssStyle">
  <label class="switch" [ngClass]="type" [ngStyle]="{'width.px': size, 'height.px': size / 1.6}">
    <input 
      type="checkbox" 
      [checked]="checked" 
      (click)="onClicked()" 
      [attr.disabled]="disabled ? true : null" 
    />
    <span class="slider round"></span>
  </label>
</div>


