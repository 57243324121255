import { Injectable } from "@angular/core";
import axios from "axios";
import { ConfigUtils } from "./config-utils";

@Injectable()
export class AxioHelper {

    constructor(
        private config: ConfigUtils
    ) { }

    public getAxiosWithHeaders() {
        return axios.create({
            headers: this.config.GetAppApiHeaders()
        });
    }

    public getAxiosWithHeadersForXLUpload() {
        return axios.create({
            headers: {
                ...this.config.GetAppApiHeaders(),
                "content-type": "multipart/form-data",
            }
        });
    }
}