<!-- <div class="popup" *ngIf="BiginwithSession"> -->
  <div class="popup" *ngIf="BiginwithSession">
    <div class="popup-overlay"  ></div>
    <div class="popup-content">
       
      <h2 class="popup-title">Hello Developer Enter the Details</h2>
      <!-- <form> -->
        <div class="form-group">
          <label for="deploymentKey" class="form-label">Deployment Key</label>
          <input [(ngModel)]="deploymentKeys"  type="text" id="deploymentKey"  class="form-input" required>
        </div>
        <div class="form-group">
          <label for="ORGID" class="form-label">Organization ID</label>
          <input  [(ngModel)]="orgid"  id="ORGID" type="text"  class="form-input" required>
        </div>
        <div class="form-group">
          <label for="AccountID" class="form-label">Account ID</label>
          <input [(ngModel)]="AccountID"  id="AccountID"  type="text" class="form-input" required>
        </div>
        <div class="form-group">
          <label for="EmailD" class="form-label">Email ID</label>
          <input [(ngModel)]="EmailID"  id="EmailD" type="email"  class="form-input" required>
        </div>
        <div class="form-group">
          <label for="UserID" class="form-label">User ID</label>
          <input [(ngModel)]="UserID"  id="UserID" type="text" class="form-input" required>
        </div>
        <div class="form-group">
          <label for="ProductName" class="form-label">Product Namr</label>
          <input [(ngModel)]="ProductName"  id="ProductName" type="text" class="form-input" required>
        </div>
        <button (click)="GenerateSession(orgid,deploymentKeys,EmailID,AccountID, UserID,ProductName)" (keyup.enter)="GenerateSession(orgid,deploymentKeys,EmailID,AccountID, UserID,ProductName)" type="submit" class="proceed-button">Proceed</button>
      <!-- </form> -->
    </div>
  </div>
  
<!-- </div> -->
<div  *ngIf="!BiginwithSession">

  <router-outlet></router-outlet>
</div>
