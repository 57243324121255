<div
  *ngIf="selctionType !== _vd_contst.ORDERINGORG_SELECTION_SIMPLE"
  class="d-flex flex-column border rounded bg-light"
>
  <div class="border-bottom px-2 pt-1">
    <div class="form-check">
      <input
        class="form-check-input"
        id="orgAllSelectChkBox"
        type="checkbox"
        [(ngModel)]="_selectAllLabs"
        (ngModelChange)="onUserSelectAll($event)"
      />
      <label class="form-check-label" for="orgAllSelectChkBox">{{label}}</label>
    </div>
  </div>
  <div>
    <vr-vdx-ordering-org-lists-of-list
      [selectAll]="_selectEventArg"
      [SelectedValues]="SelectedValues"
      [refreshData]="isDropdownValuesReady"
      (onSelected)="onSelectionChanged($event)"
      (onSelectedLabels)="onSelectionLabelChanged($event)"
      (onAllSelectedStatus)="onAllSelectedStatusChagne($event)"
    ></vr-vdx-ordering-org-lists-of-list>
  </div>
</div>

<!-- <div
  *ngIf="selctionType === _vd_contst.ORDERINGORG_SELECTION_SIMPLE"
  class="d-flex flex-column border rounded bg-light"
>
  <div class="border-bottom px-2 pt-1">
    <label class="form-check-label" for="orgAllSelectChkBox">{{label}}</label>
  </div>
  <div class="w-25 p-2">
    <vr-vdx-ordering-org-single-select
      (onChange)="onSelectionChanged($event)"
    ></vr-vdx-ordering-org-single-select>
  </div>
</div> -->
