import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DATETIME_VIEW_SAVE_FORMAT, DATE_FORMAT_INPUT, DATE_FORMAT_SAVE_INPUT } from '../helpers/app-consts';
import { MainAppUtils } from './main-app-utils';

@Injectable({
  providedIn: 'root'
})
export class DateTimeUtilsService {

  private mainUtils = new MainAppUtils();

  constructor(
    
  ) { }

  datePipe = new DatePipe('en-US');

  public FormatDateToShow = (dateString: string) => {
    try {
      let df : Date = new Date(dateString);
      let milli = df.getTime();
      return milli === this.mainUtils.GetDefaultMinDate().getTime() ? '' : 
        this.datePipe.transform(dateString, DATE_FORMAT_INPUT);
    } catch (error) {
      return ''
    }
  }

  public FormatDateToSave(dateString: string) {
    return dateString ? this.datePipe.transform(dateString, DATE_FORMAT_SAVE_INPUT) : '';
  }

  public FormatQueryDate = (d: Date) => {
    try {
      return this.datePipe.transform(d, DATE_FORMAT_SAVE_INPUT) || '';
      //return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`
    } catch (err) {
      return '';
    }
  }

  public FormatDateAsString = (d: Date, format:string) => {
    try {
      //console.log(d, format);
      return this.datePipe.transform(d, format) || '';
    } catch (err) {
      return '';
    }
  }

  public AddDays(d:Date, days:number){
    return new Date(d.getFullYear(),d.getMonth(),d.getDate()+days);
  }

  public AddMinutes(d:Date, min:number){
    return new Date(d.getFullYear(),d.getMonth(),d.getDate(),d.getHours(), d.getMinutes()-min, d.getSeconds());
  }

  public FormatDateForAPI = (d:Date) => this.FormatDateAsString(d, "yyyy-MM-dd");
}
