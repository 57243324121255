import { Component, EventEmitter, Input, Output } from '@angular/core';
import { RbDataService } from 'src/app/services/rb-data.service';

@Component({
  selector: 'app-timezone-config',
  templateUrl: './timezone-config.component.html',
  styleUrls: ['./timezone-config.component.scss']
})
export class TimezoneConfigComponent {
  constructor(
    private rdb : RbDataService
  ) { }

  @Input() selected : string = '';
  @Output() selectedChange = new EventEmitter<string>();

  _timzones = [];

  ngOnInit(): void {
    this.rdb.GetTimezones().then(res=>{
      this._timzones = res.data;
      if (this.selected) {
        this.selectedChange.emit(this.selected);
      }
  });
}
selectCategory(category: string): void {
  this.selected = category;
  this.selectedChange.emit(this.selected);
}

}
