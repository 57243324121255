import { UIListItem } from "src/app/helpers/app-models";
import { MainAppUtils } from "src/app/helpers/main-app-utils";

export class ReportConfigurationHelper {

  _mainUtils  = new MainAppUtils();

  public convertCsvToUiListItems(csvLabels: string, csvValues: string) {
    let lables = csvLabels.split(",");
    let values = csvValues.split(",");

 

    if (lables.length !== values.length) {
      console.error("Labels and Values have mismatched count");
 
      return new Array<UIListItem>();
    } else {
      let list = new Array<UIListItem>();
      for (let i = 0; i < lables.length; i++) {
        list.push({ id: i.toString(), name: lables[i], value: values[i] });
      }
      return list; //this._mainUtils.sortAscending(list,"name");
    }
  }

  public convertUiListToCsv(list: UIListItem[], key: string) {
    return list.map((o: any) => o[key]).join(",");
  }
}
