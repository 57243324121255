import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { MsgboxService } from "./msgbox.service";

@Injectable({
  providedIn: "root",
})
export class AppHelperService {
    constructor(
        private msgBox : MsgboxService
    ){}


    public HandleError(err:any){
        let keys = Object.keys(err);
        if(keys.includes("ErrorMessage") && keys.includes("StackTrace")){
            this.msgBox.error("Error", err["ErrorMessage"]);
            console.error(err.StackTrace);
        } else {
            this.msgBox.error("Error", err);
        }
    }


}
