<div class="p-2 infodetails">

    <div class=" col-sm-12 row" style="padding: 1vw;">
        <div class=" col-sm-12 pl-0">{{userDetials?.['description']}} </div>
        <div style="padding-bottom: 15px;"></div>

      <div class="col-sm-7">

        <div class="d-flex align-items-center"  *ngIf="userInfo.reportType.toLowerCase() === 'new'"  style="padding-bottom: 10px;" title="Status">
          <span>Generate Report when data is available &nbsp;</span>
          <app-checkbox-toggle
          [checked]="userDetials.isGenerate"
          (click)="onToggleClick($event)"
          (onClick)="onToggle($event, userDetials)"
          type="admin-primary"
        ></app-checkbox-toggle>
        </div>

        <div class="row mb-1">
          <span class=" col-sm-3 _600 text-muted">Filter Criteria</span>
          <span class=" col-sm-1">: </span>
          <strong class=" col-sm-6 pl-0">{{userDetials?.['criteriaDisplayNames']}} </strong>
        </div>
        <div class="row mb-1">
          <span class=" col-sm-3 _600 text-muted">Roles </span>
          <span class=" col-sm-1 ">: </span>
          <strong class=" col-sm-6 pl-0">{{userDetials?.['rolename'] || 'N/A' }}</strong>
        </div>
        <!-- <div class="row mb-1">
          <span class=" col-sm-3 _600 text-muted">Users</span>
          <span class=" col-sm-1  ">: </span>
          <strong class=" col-sm-6 pl-0">{{userDetials?.['MiddleInitial'] || 'N/A'}}</strong>
        </div> -->
    </div>
  </div>
