import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-report-audit',
  templateUrl: './report-audit.component.html',
  styleUrls: ['./report-audit.component.scss']
})
export class ReportAuditComponent {
  @Input() userDetials: any;
}
