import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, FormArray, FormGroup, FormGroupDirective, FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: CustomInputComponent,
    multi: true
  }],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class CustomInputComponent implements OnInit, OnChanges, ControlValueAccessor {
  @Input() type: string = 'text';
  @Input() labelValue: string = '';
  @Input() required: string | boolean;
  @Input() formcontrolname: string | null = null;
  @Input() maxLength: string | number = 1000;
  @Input() mask: string = '';
  @Input() prefix: string = '';
  @Input() placeholder: string = '';
  @Input() InvalidMessage: string = '';
  @Input() containerClass: string = 'mb-3 me-3';
  @Input() selectedValue: string = '';
  @Input() isDisabled: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Input() subscript: string = '';
  @Output() onFocusOut = new EventEmitter();
  @Output() onFocus = new EventEmitter();
  @Output() onImageClick = new EventEmitter();
  @Input() className: string = '';
  @Input() formarrayname: string = '';
  @Input() formgroupname: string = '';
  @Input('sideImage') img = '';
  @Input() imageType: 'side' | 'whole' | '' = '';
  _formcontrol: FormControl | null = null;

  constructor(private fcd: FormGroupDirective) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formgroupname']) {
      const formarray = this.fcd.control.get(this.formarrayname) as FormArray;
      this._formcontrol = formarray?.controls[this.formgroupname]?.get(this.formcontrolname) as FormControl || null;
    }
  }

  ngOnInit(): void {
    this.maxLength = this.mask.length > 0 ? 524288 : this.maxLength;
    if (!this.formarrayname) {
      this._formcontrol = this.fcd.control.get(this.formcontrolname) as FormControl || null;
    }
    if (this.mask && this.formcontrolname) {
      this.fcd.control.get(this.formcontrolname)?.valueChanges.subscribe((res: any) => {
        if (res === `${this.prefix} `) {
          this.fcd.control.get(this.formcontrolname)?.patchValue('');
        }
        if (res) {
          if (res[res.length - 1] === 'x') this.fcd.control.get(this.formcontrolname)?.patchValue(res.slice(0, -1));
          if (res[res.length - 1] === ',') this.fcd.control.get(this.formcontrolname)?.patchValue(res.slice(0, -1));
        }
      });
    }
  }

  focus(ref: any): void {
    if (ref.value === '' && this.prefix != '') {
      ref.value = this.prefix;
    }
  }

  get form(): FormControl | null {
    return this._formcontrol;
  }

  // Implement ControlValueAccessor methods
  writeValue(value: any): void {
    if (this._formcontrol) {
      this._formcontrol.setValue(value);
    }
  }

  registerOnChange(fn: any): void {
    if (this._formcontrol) {
      this._formcontrol.valueChanges.subscribe(fn);
    }
  }

  registerOnTouched(fn: any): void {
    // Optionally handle onTouched
  }

  setDisabledState?(isDisabled: boolean): void {
    if (this._formcontrol) {
      if (isDisabled) {
        this._formcontrol.disable();
      } else {
        this._formcontrol.enable();
      }
    }
  }
}
