import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { UserDetails } from "./app-models";
import { BrowserStorageManagerService } from "./browser-storage-manager.service";


@Injectable({
  providedIn: "root",
})
export class ConfigUtils {
  private userObject: UserDetails;

  constructor(private emmSvc: BrowserStorageManagerService) {
    this.userObject = emmSvc.GetUserInfo();
  }

  private GetApiBaseUrl(){
    return ;
  }

  public BuildApiUrl(apiMethod:string){
    return `${environment.apiUrlBase}/api/ReportListing/${apiMethod}`
  } 

  public BuildVaApiUrl(apiMethod:string){
    return `${environment.statsUrlBase}/api/v1/${apiMethod}`
  }

  public GetAppApiHeadersOld() {
    return environment.production ? this.userObject.apiHeaders : environment.dev_auth;
  }

  public GetAppApiHeaders() {
     
    return environment.production ? this.emmSvc.GetAPIHeaders() : environment.dev_auth;
  }


  public GetOrgId() {
    return environment.production
      ? this.emmSvc.GetOrgId()
      : environment.DEV_ORG_ID;
  }


  // public GetUserId(){
   
  //   return environment.production ? this.userObject.userid : environment.DEV_USER_ID;
  // }

  public GetDeploymentKey() {
    return environment.production ? this.emmSvc.GetDeploymentKey() : environment.DEV_DEPLOYAMENT_KEY;
  }

  public GetRoleName() {
    return environment.production ? this.emmSvc.GetRoleName() : environment.dev_role_name;
  } 

  // public GetLoginName() {
  //   return environment.production
  //     ? this.userObject.loginid
  //     : environment.dev_login_name;
  // }

  public GetLoggedUserFullName() {
    return environment.production
      ? this.userObject.FormattedDisplayName
      : environment.dev_login_name;
  }

  public GetConnectionName(){
    return "ReportDB";
  }

  public GetLoggedUserEmail(){
    return environment.production ? this.userObject.loginid : "devtest@kk.com";
  }

  // public GetOrgGUID() {
  //   return environment.production
  //     ? this.userObject.userorg
  //     : environment.dev_org_guid;
  // }

  // public GetUserGUID() {
  //   return environment.production
  //     ? this.userObject.userguid
  //     : environment.dev_user_guid;
  // }

  // public GetUserId() {
  //   return environment.production
  //     ? this.userObject.userid
  //     : environment.dev_user_id;
  // }

  public GetLoggedInOrganizationName() {
    return environment.production
      ? this.userObject.OrganizationName
      : "Specimen Laboratory";
  }

  // public GetUserCategory() {
  //   return environment.production ? "" : environment.dev_user_category;
  // }
  
  public GetAssociationType() {
    return environment.production
      ? this.userObject.AssociationType
      : environment.dev_association_type;
  }

  // public GetLoggedinUserAccountIds() {
  //   return environment.production
  //     ? this.userObject.userid
  //     : environment.dev_user_id;
  // }
}
