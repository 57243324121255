import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, catchError, debounceTime, of, switchMap, tap } from 'rxjs';
import { ConfigUtils } from 'src/app/helpers/config-utils';
import { MsgboxService } from 'src/app/helpers/msgbox.service';

interface User {
  userFormatedName: string;
  emailID: string;
}

@Component({
  selector: 'app-email-input-control',
  templateUrl: './email-input-control.component.html',
  styleUrls: ['./email-input-control.component.scss']
})
export class EmailInputControlComponent {
  @Output() emailIdsCsvChange = new EventEmitter<string>();
  @Input() labelValue:string=""
  emailControl = new FormControl();
  filteredEmails: User[] = [];
  selectedEmails: string[] = [];
  emailInput = '';
  isLoading$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  _formcontrol: FormControl | null = null;

   @Input() set emailIdsCsv(val:string){
    if(val != "")
      this.selectedEmails = val.split(',');
   } //: string = '';
  constructor(private http: HttpClient,private msgbox: MsgboxService, private config: ConfigUtils) {

    this.emailControl.valueChanges.pipe(
      debounceTime(300),
      tap(() => this.isLoading$.next(true)), // Show loading spinner
      switchMap(value => this.getSuggestions(value)),
      tap(() => this.isLoading$.next(false)) // Hide loading spinner
    ).subscribe((emails: User[]) => {
      this.filteredEmails = emails;
    });
  }
  get form(): FormControl | null {
    return this._formcontrol;
  }
  getSuggestions(value: string) {
    // Replace this with your actual API endpoint to fetch suggestions
    if (!value.trim()) {
      return of([]); // Return empty observable if value is empty or whitespace
    }
    const apiUrl = this.config.BuildApiUrl(`GetAccountUsers?sampleText=${value}`); //https://localhost:44368/api/ReportBuilder/GetAccountUsers?sampleText=${value}`;

    return this.http.get<User[]>(apiUrl).pipe(
      catchError(() => of([])) // Handle error gracefully
    );
  }

  removeEmail(email: string) {
    const index = this.selectedEmails.indexOf(email);
    if (index >= 0) {
      this.selectedEmails.splice(index, 1);
      this.emailIdsCsvChange.emit(this.selectedEmails.join(","));
    }
  }

  displayFn(user: User): string {
    return user && user.userFormatedName ? user.userFormatedName : '';
  }

  addEmail(user: User) {
    if (user && user.emailID && !this.selectedEmails.includes(user.emailID)) {
      this.selectedEmails.push(user.emailID);
      this.emailControl.setValue('');
    } else if (user && user.userFormatedName && !this.selectedEmails.includes(user.userFormatedName)) {
      this.selectedEmails.push(user.userFormatedName);
      this.emailControl.setValue('');
    }else if(this.isValidEmail(user.toString())){
      this.selectedEmails.push(user.toString());
      this.emailControl.setValue('');
    }else{
      this.msgbox.warning("Invalid EmailID!", "");
    }
    if(this.selectedEmails.length > 0)
      {
        this.emailIdsCsvChange.emit(this.selectedEmails.join(","));
      }
  }

  selected(event: any) {
    const email = event.option.value;
    if (this.isValidEmail(email)) {
      this.selectedEmails.push(email);
      this.emailInput = ''; // Clear input after selecting
    } else {
      alert('Invalid email format!'); // Notify user about invalid email
    }
  }

  remove(email: string) {
    const index = this.selectedEmails.indexOf(email);
    if (index >= 0) {
      this.selectedEmails.splice(index, 1);
    }
  }

  isValidEmail(email: string): boolean {
    // Basic email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  saveEmails() {
    // Here you can handle saving the selectedEmails to the database
    console.log('Saving emails:', this.selectedEmails);
  }


  ValidateEmails(){
    //console.log(this.emailIdsCsv );
    if(!this.emailIdsCsv){
      return;
    }
    const emailsArray = this.emailIdsCsv.split(',').map(email => email.trim());
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    const invalidEmails = emailsArray.filter(email => !emailRegex.test(email));
    let emails = '';
    if (invalidEmails.length > 0) {
      alert('Invalid email(s): ' + invalidEmails.join(', '));
          for (var product of invalidEmails) {
            console.log(product);
            const index = emailsArray.indexOf(product, 0);
              if (index > -1) {
                emailsArray.splice(index, 1);
              }
              this.emailIdsCsv  =  emailsArray.join(', ');
      }
    }

  }
}
