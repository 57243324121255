import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-vdx-filter-common-dropdown',
  templateUrl: './vdx-filter-common-dropdown.component.html',
  styleUrls: ['./vdx-filter-common-dropdown.component.scss']
})
export class VdxFilterCommonDropdownComponent {

  selectedObjects = new Array<any>();
  selectSomeObjects = new Array<any>();

  otherObjects = new Array<any>();
  otherSelectedObjects = new Array<any>();
  // otherSelectSomeObjects = new Array<any>();

  _isResolving = false;
  
  @Input() initValuesCsv:string = '';
  @Input() list = new Array<any>();
  @Input() param_KEY :string = '';
  @Input() param_checkBoxLabel :string = '';
  @Input() param_checkBoxLabelCss :string = '';
  @Input() param_listDataHeader:string = '';
  @Input() param_listItemLabelName:string = '';
  @Input() param_itemTextDimKeyName:string = '';
  @Input() param_listItemKeyName:string= '';

  @Output() onSelected = new EventEmitter<string>();

  onSelectedItems(e:[]){
 
    if(this._isResolving){
      this.resolveOtherObjects();
      this._isResolving = false;
    } 
    this.selectedObjects = e;
    this.raiseOnSelectedEvent();
  }

  
  resolveOtherObjects(){
    //debugger;
    let otherSelectSomeObjects = this.selectSomeObjects.filter((a:any)=>{
      return (this.list.findIndex((l:any)=>l[this.param_KEY] == a[this.param_KEY].toString()) < 0);
    });
    otherSelectSomeObjects.forEach((e:any)=>{
      if(this.otherObjects.findIndex((o:any)=>o[this.param_KEY] == e[this.param_KEY].toString() < 0))
        this.otherObjects.push(e);
    })

  }

  raiseOnSelectedEvent(){
 
    let joined = [...this.selectedObjects,...this.selectSomeObjects];
    this.onSelected.emit(joined.map((f:any)=>f[this.param_KEY]).join(','));
  }


}
