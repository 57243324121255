import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'vr-vdx-multi-selector-box',
  templateUrl: './vdx-multi-selector-box.component.html',
  styleUrls: ['./vdx-multi-selector-box.component.scss']
})
export class VdxMultiSelectorBoxComponent implements OnInit {

  constructor(
  ) {
  }

  @Input() initValuesCsv: string = '';
  @Input() listData = new Array<any>();
  @Input() listItemLabelName: string = '';
  @Input() listItemKeyName: string = '';
  @Input() listDataHeader: string = '';
  @Input() selectSome = new Array<any>();
  @Input() itemTextDimKeyName: string = '';
  @Input() preSelectLabel: string = '';

  @Input() checkBoxLabel: string = '';
  @Input() checkBoxLabelCss: string = '';
  //@Input() sendCheckBoxLabelAsFirstItemOnCheck = false;
  @Input() loading = false;

  isSelectAll = false;
  @Input() set checked(val: boolean) {
    this.isSelectAll = val;
  }

  @Output() onObjectsSelected = new EventEmitter<any>();
  @ViewChild('op') popUpPanel: any;

  selectedObjectsStatsLabel: string = '';
  selectedObjects = new Array<any>();

  ngOnInit(): void {
    if (this.preSelectLabel === this.checkBoxLabel)
      this.isSelectAll = true;

    //this.fetchLastTimeSelectedObjects()

     setTimeout(() => {
    //   console.log(this.initValuesCsv);
      if (this.initValuesCsv && this.initValuesCsv.length > 0)
        this.fetchLastTimeSelectedObjects();
    }, 800);
  }

  onSelectedObjectsReceived(e: any[]) {
    this.selectedObjects = e;
    this.onObjectsSelected.emit(e);
    this.updateSelectedStats();
  }

  openPop(e: any) {
    if (this.popUpPanel) {
      this.popUpPanel.toggle(e);
    }
  }

  onCheckToggle(e: any) {
    e.stopPropagation();
    this.isSelectAll = !this.isSelectAll;
    //this.updateSelectedStats();
  }

  updateSelectedStats() {
    if (this.selectedObjects.length === 0) {
      this.selectedObjectsStatsLabel = '';
      this.isSelectAll = false;
    }
    else if (this.selectedObjects.length === this.listData.length) {
      this.selectedObjectsStatsLabel = "ALL";
      this.isSelectAll = true;
    }
    else
      this.selectedObjectsStatsLabel = this.selectedObjects.length.toString();
  }

  fetchLastTimeSelectedObjects() {
    if (this.initValuesCsv=="All"){
       this.isSelectAll = true;
    } else{
      let vals = this.initValuesCsv.split(",");
    
      let preSelectedObjs = [];
      let idx = -1;
  
      for (let i = 0; i < vals.length; i++) {
        idx = this.listData.findIndex(x => x[this.listItemKeyName] == vals[i]);
        if (idx > -1)
          preSelectedObjs.push(this.listData[idx]);
      }
      
      if (preSelectedObjs.length > 0)
        this.selectSome = preSelectedObjs;
    }
  }

}
