<div class="row row-cols-4 gap-3 p-2">
    <div class="col" *ngFor="let list of lists">
      <vr-vdx-multi-selector-box
        [checkBoxLabel]="list.displayName"
        [preSelectLabel]="userLoggedInLabName"
        [listDataHeader]="list.displayName"
        listItemLabelName="displayName"
        [listItemKeyName]="KEY"
        [listData]="list.options"
        [initValuesCsv]="SelectedValues"
        [checked]="selectAll"
        (onObjectsSelected)="onSelectedItems(list.displayName,$event)"
      ></vr-vdx-multi-selector-box>
    </div>
  </div>
  