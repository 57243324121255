<div class="d-flex flex-row justify-content-start align-items-center gap-2">
  <div>Last</div>
  <div>
    <input
      pInputText
      type="number"
      [min]="_min"
      [max]="_max"
      class="form-control border-secondary border"
      aria-label="Text input with dropdown button"
      [(ngModel)]="_days"
      (input)="validateDaysInput($event)"
    />
  </div>
  
  <div class="date-box">
  <div class="single-select-box">
    <div class="date-item" [class.active]="_periodSelected === 'Days'" (click)="onPeriodChange('Days')">Days</div>
    <div class="date-item" [class.active]="_periodSelected === 'Weeks'" (click)="onPeriodChange('Weeks')">Weeks</div>
    <div class="date-item" [class.active]="_periodSelected === 'Months'" (click)="onPeriodChange('Months')">Months</div>
  </div>
  </div>

  <div>StartTime</div>
  <div class="form-control border-secondary border p-0 fix-overflow" style="width:100px;">
    <app-time-picker-control [(dateTime)]="_startTime" (dateTimeChange)="onReceivedValueChange()"></app-time-picker-control>
  </div>
</div>
