import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MsgboxService {

  constructor(
    private toastr : ToastrService
  ) { }

  public success(title:string, msg:string){
    this.toastr.success(msg,title);
  }

  public error(title:string, msg:string){
    this.toastr.error(msg,title);
  }

  public warning(title:string, msg:string){
    this.toastr.warning(msg,title);
  }

  public info(title:string, msg:string){
    this.toastr.info(msg,title);
  }
}
