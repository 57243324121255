import { Component, EventEmitter, Inject, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { RBOrganizationReportsDefinition, RBReports } from 'src/app/helpers/app-models';
import { RbDataService } from 'src/app/services/rb-data.service';

@Component({
  selector: 'app-new-report',
  templateUrl: './new-report.component.html',
  styleUrls: ['./new-report.component.scss']
})
export class NewReportComponent {

  searchTerm: string = '';
  reports: RBOrganizationReportsDefinition[] = [];
  showPopup: boolean = false;
  selectedReportId: number | null = null;
  report: any;
  selectedTab: string = "Daily Reports";  // Default tab
  tabList = [];  // Initialized as an empty array
  
  @Output() editReport = new EventEmitter<{ reportId: number, action: number }>();

  menu: any;
  
  _reportsList: RBReports[] = []; 
  _deploymentKey: string = '';

  @Output() onReportSelected = new EventEmitter<RBOrganizationReportsDefinition>();

  constructor(
    private router: Router,
    private rdb: RbDataService,
    public dialogRef: MatDialogRef<NewReportComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void { 
    this.LoadAll();
  }

  LoadAll() {
    console.log('Loading here');
    this.rdb.GetAllMasterSlaveReports().then((res) => {
      this._reportsList = res.data;
      this.generateTabs();
    });
  }

  generateTabs() {
    const uniqueCategories = [...new Set(this._reportsList.map(report => report.category))];
    this.tabList = uniqueCategories.map(category => ({ Htext: category }));
    this.selectedTab = this.tabList.length > 0 ? this.tabList[0].Htext : ''; // Set the default tab to the first one
  }

  get filteredReports(): RBReports[] { 
    if (this.searchTerm === '' && this.selectedTab) {
      return this._reportsList.filter(report => report.category === this.selectedTab);
    } else {
      return this._reportsList.filter(report =>
        report.category === this.selectedTab &&
        report.title.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    }
  }
  
  onProceed(reportId: number, action: number=0) {
    if (reportId !== undefined && action !== undefined) {
      this.dialogRef.close();
      this.editReport.emit({ reportId, action }); // emit the event to the parent component
      if(action == 0 ){
        this.router.navigate(['/create'], { queryParams: { reportId : btoa(reportId.toString()) } });
      }else{
        this.router.navigate(['/edit'], { queryParams: { reportId : btoa(reportId.toString()) , action : btoa(action.toString())  } });
      }
    } else {
      console.error('Report ID or action is undefined');
    }
  }
  showSlaveReports(report: RBReports): void {
    const titles = report.slaveReports.map(slaveReport => slaveReport.title).join(', ');
    alert(`Slave Reports: ${titles}`);
  }

  closePopup() {
    this.showPopup = false;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onCreateReport(): void {
    this.dialogRef.close({ event: 'Created' });
  }

  clearSearch() {
    this.searchTerm = "";
  }

  getTabData(event: any): void {
    this.selectedTab = event;
  }
}
