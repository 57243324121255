import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FiltersService } from '../../services/filters.service';

@Component({
  selector: 'app-search-box',
  templateUrl: './search-box.component.html',
  styleUrls: ['./search-box.component.scss']
})
export class SearchBoxComponent {
  @Input() placeHolder : string = 'Type To Search Here'
  @Input() className : string = ''
  @Input() isDisabled : boolean = false
  @Output() enteredValue=new EventEmitter<string>()
  @Input() searchText : string = ''
  Searchtext:string=""
  keyPressed(text:string)
  {
    this.enteredValue.emit(text);
  }

  constructor(private filterService: FiltersService ) {}

  onInputChange(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.filterService.setFilter(inputElement.value);
  }
  ClearSeachvalue()
  {
    this.filterService.setFilter("")
    this.Searchtext=""
  }

}
