<label>{{label}}:</label>
<div class="d-flex flex-column justify-content-start">
  
  <div class="d-flex flex-row" *ngFor="let item of _list; let i = index">
    <div class="form-check">
      <input
        class="form-check-input"
        [(ngModel)]="_selected"
        [value]="item.id"
        (change)="onReceiveSelected(item.id)"
        type="radio"
        [name]="label"
        [id]="i"
      />
      <label class="form-check-label fw-bold" [for]="i">
        {{item.displayName}}
      </label>
    </div>
  </div>
</div>
