<div
  class="d-flex flex-row justify-content-start align-items-center gap-2 cursor-pointer px-2 py-1 multiselect-list-item"
  (click)="raiseOnUserClick()"
>
  <div style="line-height: 0 !important;">
    <input
      style="transform: scale(1.3) !important;"
      type="checkbox"
      [checked]="isSelected"
    />
  </div>
  <div
    [style]="_style"
    [title]="object[value]"
  >
    {{object[value]}}
  </div>
</div>
