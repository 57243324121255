import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-multiselect-search-control',
  templateUrl: './multiselect-search-control.component.html',
  styleUrls: ['./multiselect-search-control.component.scss']
})
export class MultiselectSearchControlComponent {
  _optionsCopy = new Array<any>();
  _options = new Array<any>();
  @Input() set options(val: Array<any>) {
    //console.log(val)
    this._options = this.convertToInteralList(val, false);

    // console.log("writing _options data"); 
    // console.log(this._options )
    this._optionsCopy = [...this._options];
    this.onClearAll();
  }
  @Input() keyName: string = "";
  @Input() valueName: string = "";
  @Input() showClearAll: boolean = true;
  @Input() headerTitle: string = "";
  @Input() showHeaderSection = true;
  @Input() listWidth: number = 0;
  @Input() textDimKeyName: string = "";
  _selectAll :boolean = false;
  @Input() set selectAll(val: boolean) {
 
    if(this.selectAll !== val)
      this.onSelectAllClicked(val);
  }

  @Input() set selectSome(val: Array<any>) {  
    if (val && val.length > 0) {
      let count = val.length;
      for (let i = 0; i < val.length; i++) {
        this.selectObjectInList({ object: val[i], flag: true });
        
        //it runs only one time that too in the end, and it run only if there are selectSome objects
        if (i === count - 1) this.raiseSelectedObjectsEvent();
      }
    }
  }
  @Output() selectedObjects = new EventEmitter<any>();

  convertToInteralList(inputList: Array<any>, selectStatus: boolean) { 
    if (inputList)
      return inputList.map((f: any) => {
        return { object: f, selected: selectStatus };
      });
    else return [];
  }

  _query: string = "";
  _startedSearching = false;
  onSearch(e: any) {
    if (this._startedSearching === false) {
      this._startedSearching = true;
      this._query = e.target.value;
 
      if (this._query && this._query.trim().length > 0) {
        this._options = this._optionsCopy.filter((f: any) =>
          f.object[this.valueName]
            .toLocaleLowerCase()
            .includes(this._query.toLocaleLowerCase())
        );
        this._startedSearching = false;
      } else {
        this._options = [...this._optionsCopy];
        this._startedSearching = false;
      }
    }
  }

  

  onSelectAllClicked(val: boolean) {
    //this.selectAllChange.emit(val);
 
    
    if (val) {
      this.selectedObjects.emit(this._optionsCopy.map((f: any) => f.object));
      this._options = [...this._optionsCopy];
    } else {
      this.selectedObjects.emit([]);
    }

    this._selectAll = val;
    this._options.forEach((f: any) => (f.selected = val));
 
  }

  onClearAll() {
    this._options.forEach((f: any) => (f.selected = false));
    this._optionsCopy.forEach((f: any) => (f.selected = false));
    this.selectAll = false;
    this.selectedObjects.emit([]);
  }

  onItemSelected(e: any) {
    this.selectObjectInList(e);
    this.raiseSelectedObjectsEvent();
  }

  raiseSelectedObjectsEvent() {
    this.selectedObjects.emit([
      ...this._optionsCopy
        .filter((f: any) => f.selected === true)
        .map((f: any) => f.object),
    ]);
  }

  selectObjectInList(e: any) {
    //debugger;
    if (e.flag === false) this._selectAll = false;

    //There are chances that name is same but different ids, so we select all
    //same looking names.
    this._optionsCopy
      .filter((f: any) => e.object[this.keyName] == f.object[this.keyName])
      .forEach((f: any) => (f.selected = e.flag));

    if (this._query && this._query.trim().length === 0)
      this._options = [...this._optionsCopy];
    else {
      this._options
        .filter((f: any) => e.object[this.keyName] == f.object[this.keyName])
        .forEach((f: any) => (f.selected = e.flag));
    }
  }

}
