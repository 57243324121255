import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-checkbox-toggle',
  templateUrl: './checkbox-toggle.component.html',
  styleUrls: ['./checkbox-toggle.component.scss']
})
export class CheckboxToggleComponent {
  @Input() checked = false;
  @Input() size: number = 30;
  @Input() cssStyle: string = '';
  @Input() label: string = '';
  @Input() type: '' | 'secondary' | 'secondary-primary' | 'admin-primary' = '';
  @Input() disabled: boolean = false;
  @Output() onClick = new EventEmitter<boolean>();

  onClicked() {
    if (!this.disabled) {
      this.checked = !this.checked;
      setTimeout(() => {
        this.onClick.emit(this.checked);
      }, 300);
    }

  }
}
