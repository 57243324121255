<p-breadcrumb
  class="custom-breadcrumb"
  [style]="{
    'background-color': '#EFEFEF',
    'color': 'black',
    'height': '25px'
  }"
  [model]="items">
</p-breadcrumb>

<ng-container>
  <section class="lab-user-creation py-3" [formGroup]="reportForm">
    <div class="d-flex">
      <div class="vdm-heading">
        <span class="section-title">Report Information</span>
      </div>
    </div>

    <div class="menu-item mb-3">
      <div>
        <label>
          <input type="radio" formControlName="reportType" value="master" />
          Master Report
        </label>
        <label class="ms-3">
          <input type="radio" formControlName="reportType" value="slave" />
          Slave Report
        </label>
      </div>
    </div>

    <div *ngIf="reportForm.get('reportType')?.value === 'master'">
      <input
        type="checkbox"
        id="isOrganization-checkbox"
        formControlName="isOrganization"
        class="me-2"
      />
      <label for="isOrganization-checkbox">Apply to this Organization only.</label>
      <div style="padding: 5px;"></div> 
    </div>   

    <div class="row-container d-flex mb-3">
      <div class="form-column" style="flex: 1;">
        <form [formGroup]="reportForm">
          <app-custom-input
            labelValue="Report Name"
            formcontrolname="Title"
            required="true"
            placeholder="Enter Report Name">
          </app-custom-input>
        </form>
      </div>

      <div class="config-column" style="flex: 1;">
        <form [formGroup]="reportForm">
          <app-custom-input
            labelValue="Description"
            formcontrolname="Description"
            required="true"
            placeholder="Enter Description">
          </app-custom-input>
        </form>
      </div>
    </div>

    <div class="row-container d-flex mb-3">
      <div class="form-column" style="flex: 1;">
        <form [formGroup]="reportForm">
          <app-custom-input
            labelValue="SP Name"
            formcontrolname="spname"
            required="true"
            placeholder="Enter SP Name">
          </app-custom-input>
        </form>
      </div>
      <div class="config-column" style="flex: 1;">
        <form [formGroup]="reportForm">
          <app-custom-input
            labelValue="Named Range"
            formcontrolname="namedrange"
            required="true"
            placeholder="Enter Named Range">
          </app-custom-input>
        </form>
      </div>
    </div>

    <div class="row-container d-flex mb-3">
      <div class="form-column" style="flex: 1;">
        <div class="mb-3">
          <span class="label-header">Version <label class="text-danger fw-bold fs-6">*</label></span>
          <select class="form-select vdx-form-select" formControlName="selectedVersion" (change)="onValueSelected($event)">
            <option [ngValue]="null" disabled>Select Version</option>
            <option *ngFor="let item of list" [value]="item.value">{{ item.label }}</option>
          </select>       
        </div>
        
        <div class="mb-3">
          <span class="label-header">Max Days </span>
          <input
          type="number"
          id="maxdays"
          formControlName="MaxDays"
          min="1"
          max="365"
          class="texting" 
          (input)="validateDaysInput($event)"
        />
        </div>

        <div class="mb-3">
          <span class="label-header">Template File </span>
          <input type="file" #templateFile class="form-control" accept=".xls,.xlsx" (change)="onFileSelectedToUpload($event)" />
        </div>
      </div>

      <!-- Configuration Section -->
      <div class="config-column" style="flex: 1;">
        <span class="label-header">Configuration <label class="text-danger fw-bold fs-6">*</label></span>
        <div class="menu-container">
          <div class="menu-item d-flex align-items-center">
            <label for="active-checkbox" class="me-1">Active</label>
            <input type="checkbox" id="active-checkbox"  formControlName="isactive" />
          </div>

          <div class="menu-item d-flex align-items-center">
            <label for="async-checkbox" class="me-1">Asynchronous</label>
            <input type="checkbox" id="async-checkbox" formControlName="isasynchronous"/>
          </div>

          <div class="menu-item d-flex align-items-center">
            <label for="apprextime" class="me-1">Approx. Generation Time</label>
            <input type="number" id="apprextime" formControlName="apprGenerationTime"/>
          </div>

          <div class="menu-item d-flex align-items-center mt-1">
            <label for="multi-sheet-checkbox" class="me-1">Enable Multi-Sheet</label>
            <input type="checkbox" id="multi-sheet-checkbox" formControlName="ismultisheet"/>
          </div>

          <div class="menu-item d-flex align-items-center mt-1">
            <label for="connection-name-checkbox" class="me-1">Connection Name</label>
            <div class="menu-item d-flex align-items-center mt-1 ms-2">
              <input type="radio" id="reportdb-radio" name="connectionName" formControlName="connectionname" value=""/>
              <span class="ms-1">None</span>
            </div>
            <div class="menu-item d-flex align-items-center mt-1 ms-4">
              <input type="radio" id="reportdb-radio" name="connectionName" formControlName="connectionname" value="ReportDB"/>
              <span class="ms-1">ReportDB</span>
            </div>
            <div class="menu-item d-flex align-items-center mt-1 ms-4">
              <input type="radio" id="coredb-radio" name="connectionName" formControlName="connectionname" value="CoreDB"/>
              <span class="ms-1">CoreDB</span>
            </div>
          </div>
          <div *ngIf="reportForm.get('connectionname')?.value === 'CoreDB'" class="form-column mt-3" style="flex: 1;">
            <form [formGroup]="reportForm">
              <app-custom-input
                labelValue="Parameter Name"
                formcontrolname="parametername"
                required="true"
                placeholder="Enter comma separated Names">
              </app-custom-input>
            </form>
          </div>
        </div>
      </div>
    </div>

    <!-- Report Categories -->
    <div class="mb-3" style="padding: 5px;">
      <app-report-categories
      [isAdd]="true"
      [(selected)]="_selectedReportForSave.category">
      </app-report-categories>
    </div>

    <!-- Criterias -->
    <div class="mb-3" style="padding: 5px;">
      <app-filter-criteria
        [isselect]=false
        [actualCriteria]="_master_criteria"
        (onDataChanged)="onReceivedFilterSelection($event)">
      </app-filter-criteria>
    </div>

    <!-- Role Mapping -->
    <div class="mb-3" style="padding: 5px;" *ngIf="reportForm.get('reportType').value === 'slave'">
      <app-role-mapping [(selected)]="_selectedReportForSave.roles"></app-role-mapping>
    </div>  

    <!-- columns -->
    <div class="mb-3" style="padding: 5px;">
      <form [formGroup]="reportForm">
        <app-custom-input
          labelValue="Columns"
          formcontrolname="Columns"
          required="true"
          placeholder="Enter comma separated values">
        </app-custom-input>
      </form>
    </div>

    <div *ngIf="reportForm.get('reportType').value === 'slave'">
      <div class="d-flex mb-3">
        <div class="vdm-heading">
          <span style="font-size: 13px;">Schedule</span>
        </div>
      </div>
  
      <!-- Schedules -->
      <div *ngIf="_loadSchedules && _showReportDetails" class="d-flex flex-column justify-content-start gap-2">
        <div class="px-4 w-100" *ngIf="showloader">
          <app-shimmerui [line]="4"></app-shimmerui>
        </div>
        <div *ngFor="let schedule of _schedules; let i = index">
          <app-criteria-configuration
            [schedule]="schedule"
            [index]="i"
            (onDelete)="removeSchedule($event, schedule.jobId, schedule.reportId, this._selectedReportForSave.action)"
            [dataFilters]="_filtersAndData">
          </app-criteria-configuration>
        </div>
        <div class="text-center pb-3" *ngIf="!showloader">
          <button class="col-sm-3 " style="background-color: #1f79ce; color: #F5F5F5;border: none;border-radius: 5px;min-height:4vh;" (click)="addNewSchedule() ">
            <label> <span style="font-size: 20px;margin-right: 5px;">+</span>Add Schedule</label></button>
        </div>
      </div>
    </div>
  </section>
</ng-container>

<!-- Footer -->
<div class="footer">
  <div class="d-flex flex-row bg-black-alpha-10 p-2">
    <div class="flex-fill"></div>
    <div class="d-flex flex-row gap-3">
      <button pButton (click)="OnReturnClick()" class="btn-sec">Return</button>
      <button pButton class="btn-pri" *ngIf="reportForm.get('reportType').value === 'master'"
       (click)="Save(0,true)">Publish Report</button>
      <button pButton class="btn-pri" *ngIf="reportForm.get('reportType').value === 'slave'" 
      (click)="Save(2)">Save As Draft</button>
      <button pButton class="btn-pri" *ngIf="reportForm.get('reportType').value === 'slave'"  
      (click)="Save(1)">Publish Report</button>
    </div>
  </div>
</div>
