import { SelectionModel } from '@angular/cdk/collections';
import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray, transferArrayItem,CdkDrag, CdkDropList} from '@angular/cdk/drag-drop';
import { FiltersService } from '../../../../services/filters.service';

@Component({
  selector: 'app-column-configuration',
  templateUrl: './column-configuration.component.html',
  styleUrls: ['./column-configuration.component.scss']
})
export class ColumnConfigurationComponent implements OnInit {
 
  masterListLocal: { name: string; category: string }[] = [];
  userSelectionList: { name: string; category: string }[] = [];
  searchTerm: string = '';

  @Input() masterList: { name: string; category: string }[] = []; 
  @Input() userSelectionList1: { name: string; category: string }[] = [];
  @Output() userSelectedColumns = new EventEmitter<{ name: string; category: string }[]>();
  @Output() closeConfiguration = new EventEmitter<void>();

  expandedCategories: { [key: string]: boolean } = {};
  moveElemetOne: SelectionModel<any> = new SelectionModel<any>(true, []);
  moveElemetTwo: SelectionModel<any> = new SelectionModel<any>(true, []);

  intialFilterList:any[]=[]
  selectedFilterList:any[]=[]

  selectedItemsList1: Set<{ name: string; category: string }> = new Set();
  selectedItemsList2: Set<{ name: string; category: string }> = new Set();

  constructor(private filterService: FiltersService) { }
  get masterListCategories(): string[] {
    return [...new Set(this.masterListLocal.map(item => item.category))].sort();
  }

  get userSelectionCategories(): string[] {
    return [...new Set(this.userSelectionList.map(item => item.category))].sort();
  }
  getItemsByCategory(list: 'masterListLocal' | 'userSelectionList', category: string) {
    return this[list].filter(item => item.category === category);
  }

  toggleCategoryExpansion(category: string) {
    this.expandedCategories[category] = !this.expandedCategories[category];
  }

  ngOnInit() {
    this.userSelectionList = this.userSelectionList1;
    
    this.filterService.filter$.subscribe(filter => {
      // Reset expanded categories before each search
      this.expandedCategories = {};
  
      if (!filter.trim()) {
        this.masterListLocal = this.masterList;
      } else {
        this.masterListLocal = this.masterList.filter(item => {
          const isMatch = item.name.toLowerCase().includes(filter.toLowerCase());
          if (isMatch) {
            // Automatically expand the category if a matching item is found
            this.expandedCategories[item.category] = true;
          }
          return isMatch;
        });
      }
    });
  }
  

  addItems() {
    this.userSelectedColumns.emit(this.userSelectionList);
  }

  close() {
    this.closeConfiguration.emit();
  }

  clearSearch() {
    this.searchTerm = "";
  }

    onDrop(event: CdkDragDrop<string[]>, listName: string) { 
    const previousIndex = this.userSelectionList.findIndex((d) => d === event.item.data);
    if (event.previousContainer === event.container) {
      // Reorder within the same list (optional, if needed) 
      if (event.container.id){
        const movedItem = this.userSelectionList[previousIndex];
      this.userSelectionList.splice(previousIndex, 1);
      this.userSelectionList.splice(event.currentIndex, 0, movedItem);
      }
    } else{
      const movedItem = event.item.data;
      if(movedItem?.length > 0){
        movedItem.forEach(element => {
          this.insertToUserList(element)
        });
      }
      else if (movedItem) {
        this.insertToUserList(movedItem)
      }
    }
  }

  isSelected(item: { name: string; category: string }, list: string): boolean {
    const selectedItems = list === 'masterListLocal' ? this.selectedItemsList1 : this.selectedItemsList2;
    return Array.from(selectedItems).some(i => i.name === item.name && i.category === item.category);
  }
  

  toggleSelection(item: { name: string, category: string }, list: 'masterListLocal' | 'userSelectionList', event: any) {
    const selectedItems = list === 'masterListLocal' ? this.selectedItemsList1 : this.selectedItemsList2;

    if (!event.shiftKey) {
      selectedItems.clear();
    }

    if (Array.from(selectedItems).some(i => i.name === item.name && i.category === item.category)) {
      selectedItems.forEach(i => {
        if (i.name === item.name && i.category === item.category) {
          selectedItems.delete(i);
        }
      });
    } else {
      selectedItems.add(item);
    }
  }

  moveToList2(item: { name: string, category: string }) {
    const index = this.masterListLocal.findIndex(x => x.name === item.name && x.category === item.category);
    
    if (index >= 0) {
      // Ensure the item is not already in the userSelectionList
      const isAlreadySelected = this.userSelectionList.some(x => x.name === item.name && x.category === item.category);
      if (!isAlreadySelected) {
        this.userSelectionList.push(item);
      }
    }
  }
  

  moveSelectedItems(fromList: string, toList: string) {
    const from = this[fromList] as { name: string; category: string }[];
    const to = this[toList] as { name: string; category: string }[];
    const selectedItems = fromList === 'masterListLocal' ? this.selectedItemsList1 : this.selectedItemsList2;
  
    selectedItems.forEach(item => {
      const index = from.findIndex(i => i.name === item.name && i.category === item.category);
      
      if (index >= 0) {
        // If moving from master to slave, just copy, no removal from master
        if (fromList === 'masterListLocal') {
          if (to.findIndex(i => i.name === item.name && i.category === item.category) < 0) {
            // Copy to the slave list if not already present
            to.push(item);
          }
        } 
        // If moving from slave to master, remove from slave list
        else if (fromList === 'userSelectionList') {
          if (to.findIndex(i => i.name === item.name && i.category === item.category) < 0) {
            // Move back to master list and remove from slave
            to.push(item);
          }
          // Remove from the slave list
          from.splice(index, 1);
        }
      }
    });
  
    // Clear selection after moving/copying
    if (fromList === 'masterListLocal') {
      this.selectedItemsList1.clear();
    } else {
      this.selectedItemsList2.clear();
    }
  }
  

  removeItem(item: { name: string; category: string }) {
    const index = this.userSelectionList.findIndex(i => i.name === item.name && i.category === item.category);
    if (index >= 0) {
      this.userSelectionList.splice(index, 1);
    }
  }
  
  isInUserSelectionList(item: { name: string; category: string }): boolean {
    return this.userSelectionList.some(i => i.name === item.name && i.category === item.category);
  }
  
  insertToUserList(element : { name: string; category: string }) {
    const index = this.userSelectionList.findIndex((e) => e.category == element.category && e.name == element.name);
    if (index < 0) {
      this.userSelectionList.push(element);
    }
  }

  removeAll(category : string){
    this.getItemsByCategory('userSelectionList', category).forEach(eachItem => {
      this.removeItem(eachItem)
    })
  }

  allCategorySelected(category : string) : boolean{
    return this.getItemsByCategory('masterListLocal', category).length == this.getItemsByCategory('userSelectionList', category).length
  }
}
