<div class="menu-header">
    <span class="label-header" >Select TimeZone<label class="text-danger fw-bold fs-6"  >*</label></span>
</div>
  
  <div class="row-container">
    <div class="menu-container">
      <div class="single-select-menu">
        <div
          *ngFor="let category of _timzones"
          (click)="selectCategory(category)"
          [class.selected]="category === selected"
          class="menu-item"
        >
          {{ category }}
        </div>
      </div>
    </div>
  
  </div>
  