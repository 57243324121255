<div class="detailsTab">
  <mat-tab-group (selectedTabChange)="onTabChanged($event)" #tabGroup>
    <mat-tab *ngFor="let item of tabData; let i = index;">
      <ng-template mat-tab-label class="cursor_pointer">
        <span >{{ item.Htext }}</span>
      </ng-template>
      <!-- Content for each tab -->
      <div *ngIf="activeTab === item.Htext">
        <!-- Tab-specific content goes here -->
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
