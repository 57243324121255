import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VdxDropdownsDataManagerService } from '../../vdx-helpers/vdx-dropdowns-data-manager.service';
import { MainAppUtils } from 'src/app/helpers/main-app-utils';
import * as appConsts from '../../helpers/stats-constants';

@Component({
  selector: 'app-vdx-algorithm-options',
  templateUrl: './vdx-algorithm-options.component.html',
  styleUrls: ['./vdx-algorithm-options.component.scss']
})
export class VdxAlgorithmOptionsComponent {
  _list = new Array<any>();
  _selected : string = '';
  @Input() label :string = '';
  @Input() set selected(val: string) {
    this._selected = val;
  }
  @Output() onValueSelected  = new EventEmitter<string>();

  constructor(
    private vdxDdlData: VdxDropdownsDataManagerService,
  ) { }

  ngOnInit(): void {
    this._list = new MainAppUtils().getData(this.vdxDdlData,appConsts.VDXReportFilterTypes.ALGORITHM_TYPE); //this._list = this.vdxDdlData.GetData()[appConsts.VDXReportFilterTypes.ALGORITHM_TYPE];
    
    if(this._list && this._list.length >0){
      this._selected = this._list[0].id;
      this.onValueSelected.emit(this._selected);
    }
  }

  onReceiveSelected(e:string){
    this._selected = e;
    this.onValueSelected.emit(e);
  }

}
