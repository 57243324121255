<app-vdx-filter-common-dropdown
  [param_checkBoxLabel]="param_checkBoxLabel"
  [param_checkBoxLabelCss]="labelCss"
  [param_listDataHeader]="param_listDataHeader"
  [param_listItemLabelName]="param_listItemLabelName"
  [param_itemTextDimKeyName]="param_itemTextDimKeyName"
  [param_listItemKeyName]="param_listItemKeyName"
  [param_KEY]="param_KEY"
  [list]="list"
  [initValuesCsv]="initValuesCsv"
  (onSelected)="onSelected.emit($event)"
>
</app-vdx-filter-common-dropdown>
