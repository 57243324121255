<div class="d-flex flex-column gap-1 p-2 w-100" *ngIf="showHeaderSection" [style]="{width:listWidth+'px'}">
    <div class="d-flex flex-row justify-content-end align-items-center gap-3 text-white ps-1 pe-2">
      <div *ngIf="headerTitle" class="flex-grow-1 fw-bold" style="font-size: 1.1em;">{{headerTitle}}</div>
      <div
        class="d-flex flex-row gap-1 justify-content-start align-items-center cursor-pointer"
        (click)="onSelectAllClicked(!_selectAll)"
      >
        <div class="lh-zero">
          <input style="transform: scale(1.2) !important;" type="checkbox" [checked]="_selectAll"/>
        </div>
        <div class="lh-zero">Check All</div>
      </div>
      <div *ngIf="showClearAll" class="cursor-pointer lh-zero" (click)="onClearAll()">
        <app-bootstrap-icon IconName="x-circle" [IconSize]="14"></app-bootstrap-icon>&nbsp;Uncheck All
      </div>
      <!-- <div class="cursor-pointer" (click)="onClearAll()">
        <va-bootstrap-icon IconName="check2" [IconSize]="14"></va-bootstrap-icon>&nbsp;Show selected
      </div> -->
    </div>
    <!-- <div>
      <span *ngIf="_startedSearching" style="font-size: 10px;"
        >Please wait...</span
      >
    </div> -->
  </div>
  <div class="bg-white p-2" *ngIf="showHeaderSection">
    <input type="text" autofocus (input)="onSearch($event)" placeholder="Type here to search..." />
  </div>
  <div
  *ngIf="_options.length ===0"
  class="p-2 bg-white w-100"
  >
  No items to show.
  </div>
  <div *ngIf="_options && _options.length > 0" style="max-height: 250px; overflow-y: auto;" class="bg-white">
    <app-multiselect-list-item
      *ngFor="let item of _options"
      [value]="valueName"
      [key]="keyName"
      [itemTextDimKeyName]="textDimKeyName"
      [itemWidth]="listWidth"
      [isSelected]="item.selected"
      [object]="item.object"
      (onUserClick)="onItemSelected($event)"
    ></app-multiselect-list-item>
  </div>
  