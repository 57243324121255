import { Component, OnInit } from '@angular/core';
import { allReports } from 'src/app/helpers/app-models';
import { RbDataService } from 'src/app/services/rb-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NewReportComponent } from '../main-new-report/new-report/new-report.component';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  selectedUserdat: any;
  showDetails: boolean = false;
  searchText: string = "";
  filter: string = 'All';
  selectedMenu: string = '';
  allReports: allReports[] = [];
  distinctMenus: string[] = [];
  showloader: boolean = true;
  items: MenuItem[];
  
  allCount: number = 0;
  publishedCount: number = 0;
  unpublishedCount:number=0;
  draftCount: number = 0;
  noSchedulesCount: number = 0;

  

  constructor(private router: Router, private rdb: RbDataService, public dialog: MatDialog) {}

  AllReports(): void {
    this.rdb.GetAllReports().then(
      (res: any) => {
        this.allReports = res.data;
        console.log("", this.allReports);
        this.showloader = false;
        this.distinctMenus = [...new Set(this.allReports.map(report => report.menu))];
        this.computeCounts();
      },
      (error: any) => {
        console.error('Error fetching reports:', error);
      }
    );
  }

  ngOnInit(): void {
    this.items = [
      { label: 'Statistics', routerLink: '/' },
    ];
    this.AllReports();
  }

  computeCounts(): void {
    this.allCount = this.allReports.length;
    this.publishedCount = this.allReports.filter(report => report.published && report.isActive).length;
    this.unpublishedCount = this.allReports.filter(report => report.published && !report.isActive).length;
    this.draftCount = this.allReports.filter(report => !report.published).length;
    this.noSchedulesCount = this.allReports.filter(report => this.hasNoSchedules(report.schedules)).length;
  }

  hasNoSchedules(schedules: string): boolean {
    // Extract the first value before the parenthesis
    const firstValue = schedules.split(' ')[0];
    // Return true if the first value is '0'
    return firstValue === '0';
  }

  clearSearch() {
    this.searchText = "";
  }

  cardSelect(event: any) {
    this.showDetails = true;
    this.selectedUserdat = event;
  }

  closeUserDetails(event: any) {
    this.showDetails = event;
  }

  navigateToNewReport(): void {
    const dialogRef = this.dialog.open(NewReportComponent, {
      width: '100vw',
      height:'80vh',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event === 'Created') {
        this.AllReports(); // Refresh the reports list after creating a new report
      }
    });
  }

  setFilter(filter: string): void {
    this.filter = filter;
  }

  onMenuChange(menu: string): void {
    this.selectedMenu = menu;
  }
}
