import { Component } from '@angular/core';

@Component({
  selector: 'app-other-ordering-org-search',
  templateUrl: './other-ordering-org-search.component.html',
  styleUrls: ['./other-ordering-org-search.component.scss']
})
export class OtherOrderingOrgSearchComponent {

}
