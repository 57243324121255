<div class="top-navigation-bar row bg-secondary">
Statistics
   <mat-icon  class="navigation-bar-chev">chevron_right</mat-icon>
      </div>
      
      
      
    <!-- <div class="top-navigation-bar row bg-secondary">
      <ng-container *ngFor="let item of navigationList$ | async;let i=index;">
        <span class="cursor-pointer" (click)="changeRoute(item,i)">{{item.Htext || item.URL}}</span>
        <mat-icon *ngIf="i !== (navigationList$|async)?.length-1 " class="navigation-bar-chev">chevron_right</mat-icon>
      </ng-container>
    </div>
       -->
      
      
      