// idle.service.ts

import { Injectable } from '@angular/core';
import { Observable, Subject, timer } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class idleService {
  private readonly MAX_IDLE_TIME = 30 * 60 * 1000; // 30 minutes in milliseconds
  private idleTimeout$ = new Subject<void>();

  // constructor() {
  //   this.initIdleTimer();
  // }

  // private initIdleTimer(): void {
  //   let lastActivityTime = Date.now();

  //   const activity$ = new Subject<void>();

  //   // Listen to user activity
  //   document.addEventListener('mousemove', () => {
  //     lastActivityTime = Date.now();
  //     activity$.next();
  //   });

  //   // Start the idle timer
  //   timer(0, 1000) // Check every second
  //     .pipe(
  //       takeUntil(this.idleTimeout$),
  //       tap(() => {
  //         const idleTime = Date.now() - lastActivityTime;
  //         if (idleTime >= this.MAX_IDLE_TIME) {
  //           this.showRefreshPrompt();
  //           this.idleTimeout$.next();
  //         }
  //       })
  //     )
  //     .subscribe();
  // }

  // private showRefreshPrompt(): void {
  //   alert('Your session will expire soon. Please refresh the page.');
  //   // You can customize this to show a modal or a more user-friendly message
  // }
}
