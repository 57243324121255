import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shimmerui',
  templateUrl: './shimmerui.component.html',
  styleUrls: ['./shimmerui.component.scss']
})
export class ShimmeruiComponent {
  @Input() line:number=9
  @Input() col:number=3
  shmier =[1,2,4,3,4,4,4,4,4]

}
