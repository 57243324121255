import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UIListItem } from 'src/app/helpers/app-models';

@Component({
  selector: 'app-filter-criteria',
  templateUrl: './filter-criteria.component.html',
  styleUrls: ['./filter-criteria.component.scss']
})
export class FilterCriteriaComponent {

  @Output() onDataChanged = new EventEmitter<Array<UIListItem>>();
  @Input() isselect =true;
  @Input() actualCriteria = new Array<UIListItem>();
  @Input() selectedCriteria = new Array<UIListItem>();

  areAllRolesSelected: boolean = false;

  ngOnInit(): void {
    if(this.isselect==true){
      if (this.selectedCriteria && this.selectedCriteria.length > 0) {
        this.updateSelectAllState();
      } else {
        this.selectedCriteria = [...this.actualCriteria]; // Select all criteria initially
        this.areAllRolesSelected = true; // Check the "Select All" checkbox
        this.onDataChanged.emit(this.selectedCriteria); // Emit the initial selection
      }
    }else{
      this.areAllRolesSelected =false;
    }
  }
  
  get selectedCount(): number {
    return this.selectedCriteria.length;
  }

  toggleSelectAll(event: any): void {
    this.areAllRolesSelected = event.target.checked;
    if (this.areAllRolesSelected) {
      this.selectedCriteria = [...this.actualCriteria];
    } else {
      this.selectedCriteria = [];
    }
    this.onDataChanged.emit(this.selectedCriteria);
  }

  toggleSelection(role: UIListItem): void {
    const index = this.selectedCriteria.findIndex(item => item.id === role.id);
    if (index === -1) {
      this.selectedCriteria.push(role);
    } else {
      this.selectedCriteria.splice(index, 1);
    }
    this.updateSelectAllState();
    this.onDataChanged.emit(this.selectedCriteria);
  }
  
  isSelected(role: UIListItem): boolean {
    return this.selectedCriteria.some(item => item.id === role.id);
  }

  private updateSelectAllState(): void {
    this.areAllRolesSelected = this.selectedCriteria.length === this.actualCriteria.length;
  } 

}
