<div>
  <label class="label-header">Select Filter Criteria<label class="text-danger fw-bold fs-6"  >*</label>&nbsp;
    <input type="checkbox" [checked]="areAllRolesSelected" (change)="toggleSelectAll($event)" />
    <strong style="padding-left: 10px;">Selected: {{ selectedCount }}</strong>
  </label>
</div>

<div class="criteria-mapping">
  <div
    *ngFor="let criteria of actualCriteria"
    [class.selected]="isSelected(criteria)"
    (click)="toggleSelection(criteria)"
    class="criteria-item">
    {{ criteria.name }}
  </div>
</div>
