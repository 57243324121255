<div class="d-flex flex-column border rounded bg-light">
    <div class="border-bottom px-2 pt-1">
      <div class="form-check">
        <input
          class="form-check-input chk-blue"
          id="labAllSelectChkBox"
          type="checkbox"
          [ngModel]="_selectAllLabs"
          (ngModelChange)="onUserSelectAll($event)"
        />
        <label class="form-check-label" for="labAllSelectChkBox">{{label}}</label>
      </div>
    </div>
    <div>
      <vr-vdx-labs-dropdown
        [SelectedValues]="SelectedValues"
        [selectAll]="_selectEventArg"
        [refreshData]="isDropdownValuesReady"
        (onSelected)="onSelectionChanged($event)"
        (onSelectedLabels)="onSelectionLabelChanged($event)"
        (onAllSelectedStatus)="onLabSelectedStatusChagne($event)"
      ></vr-vdx-labs-dropdown>
    </div>
  </div>
  