import { Component, Input } from '@angular/core';
import { VdxBulkSelectorBaseComponent } from '../vdx-bulk-selector-base';

@Component({
  selector: 'vr-vdx-bulk-laboratory-selector',
  templateUrl: './vdx-bulk-laboratory-selector.component.html',
  styleUrls: ['./vdx-bulk-laboratory-selector.component.scss']
})
export class VdxBulkLaboratorySelectorComponent extends VdxBulkSelectorBaseComponent {
  
  @Input() SelectedValues : string = '';


}
