<span [ngClass]="labelActiveText.toLowerCase() === 'active' ? 'label-text' : ''">
    {{ _checkedStatus ? labelActiveText : labelInactiveText }}
  </span>
  <span [class.ml-2]="labelActiveText.length" *ngIf="formControlName">
    <label class="switch" [id]="id">
      <input #inp type="checkbox" [checked]="_checkedStatus" (click)="emitValue($event)" [formControlName]="formControlName" [attr.disabled]="isDisabled ? true : null" />
      <span class="slider round" [ngClass]="isDisabled ? 'disable' : ''"></span>
    </label>
  </span>
  <span class="ml-2" *ngIf="!formControlName">
    <label class="switch" [id]="id">
      <input #inp type="checkbox" [checked]="_checkedStatus" [disabled]="isDisabled" (click)="emitValue($event)" />
      <span class="slider round" [ngClass]="isDisabled ? 'disable' : ''"></span>
    </label>
  </span>
  