import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { VdxDropdownsDataManagerService } from '../../vdx-helpers/vdx-dropdowns-data-manager.service';

@Component({
  selector: 'app-service-type-dropdown',
  templateUrl: './service-type-dropdown.component.html',
  styleUrls: ['./service-type-dropdown.component.scss']
})
export class ServiceTypeDropdownComponent implements OnInit {

  constructor(
    private vdxDdlManager : VdxDropdownsDataManagerService
  ) { }

  list = new Array<any>();
  @Output() onSelected = new EventEmitter<any>();

  ngOnInit(): void {
    this.list = this.vdxDdlManager.GetServiceTypes()
  }

  onValueSelected(e:any){
    this.onSelected.emit(e.target.value);
  }

}
