import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RbDataService } from 'src/app/services/rb-data.service';

@Component({
  selector: 'app-sample-preview',
  templateUrl: './sample-preview.component.html',
  styleUrls: ['./sample-preview.component.scss']
})
export class SamplePreviewComponent {
  isLoading = true; // Start loading state as true
  previewList: any[] = []; // To store the preview list

  constructor(
    public dialogRef: MatDialogRef<SamplePreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, 
    private rdb: RbDataService, 
  ) {
    this.loadPreviewData(); // Call to load the data
  }

  close(): void {
    this.dialogRef.close();
  }

  loadPreviewData(): void {
    this.rdb.SamplePreview(this.data.jobID, this.data.reportId, this.data.mastercolumn)
      .then((response: any) => {
        this.previewList = response.data; 
        this.isLoading = false; 
      })
      .catch(error => {
        console.error('Error fetching report preview:', error);
        this.isLoading = false; 
      });
  }

  getKeys(reportData: any): string[] {
    return reportData ? Object.keys(reportData) : [];
  }
}
