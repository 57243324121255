import { ToastrService } from "ngx-toastr";
import { VdxDropdownsDataManagerService } from "../report-design/vdx-filters-builder/vdx-helpers/vdx-dropdowns-data-manager.service";
//import { VdxDropdownsDataManagerService } from "../report-filters/vdx-filters-builder/vdx-helpers/vdx-dropdowns-data-manager.service";

export class MainAppUtils {

  public RemoveDuplicates(inputArray: Array<any>, keyNameToCheckValue: string) {
    return inputArray.filter(
      (value, index, self) =>
        index !==
        self.findIndex((t) => {
          return t[keyNameToCheckValue] === value[keyNameToCheckValue];
        })
    );
  }
  
  public isObjectUndefined(obj: any): boolean {
    if (obj === null) {
      return true;
    }
    if (obj === "") return true;

    return typeof obj === "undefined";
  }

  public isEmptyOrSpaces(str: string): boolean {
    if (this.isObjectUndefined(str)) {
      return true;
    }

    str = str.replace(" ", "");

    if (str === "") {
      return true;
    }

    if (str === null) {
      return true;
    }

    let result = str && str.match(/^ *$/) !== null;
    return result as boolean;
  }

  public GetDefaultMinDate() {
    return new Date(1970, 1, 1);
  }

  public IsMinYearDate(dt: Date) {
    return dt.getFullYear() === 1970;
  }

  public GetInvalidDate() {
    let em: any = undefined;
    //console.log(new Date(em));
    return new Date(em);
  }
  public IsValidDate(val: string) {
    try {
      if (val === "") return false;
      let d = new Date(val);
      let milli = d.getTime();
      let isValid = !isNaN(milli);
      if (isValid) return this.IsMinYearDate(d) ? false : true;
      return false;
    } catch (err) {
      return false;
    }
  }

  public IsMaskedDateInputProper = (dt: string) => dt && dt !== "__-__-____";

  public CopyObject(obj: any) {
    let stringJson = JSON.stringify(obj);
    return JSON.parse(stringJson);
  }

  IsEmptyDates = (dts: string[]) => dts[0] === "" && dts[1] === "";
  IsValidDates = (dts: string[]) =>
    this.IsValidDate(dts[0]) && this.IsValidDate(dts[1]);

  IsInvalidDate = (dts: string) => {
    return dts.toString() === "Invalid Date";
  };

  IsInvalidDateObject = (dts: Date) => {
    return dts.toString() === "Invalid Date";
  };
  IsInvalidDates = (dts: Date[]) => {
    //console.log(dts[0].toString(), dts[0].toString() === 'Invalid Date');
    return (
      dts[0].toString() === "Invalid Date" ||
      dts[1].toString() === "Invalid Date" ||
      dts[0].toString() === "" ||
      dts[1].toString() === ""
    );
  };

  public StartBrowserDownload(response: any, filenameWithExtn: string) {
    console.log(filenameWithExtn, response.headers);
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = url;
    filenameWithExtn = filenameWithExtn ? filenameWithExtn : new Date().getTime() + ".xlsx";
    link.setAttribute("download", filenameWithExtn); //or any other extension
    document.body.appendChild(link);
    link.click();
  }
  public GetDateDiffInDays(start: Date, end: Date) {
    var Difference_In_MilliSec = end.getTime() - start.getTime();
    if(Difference_In_MilliSec > 0)
        return Difference_In_MilliSec / (1000 * 3600 * 24);
    else
        return 0;
  }

  parseBlobAndShowError(err: any, toast: ToastrService) {
    toast.clear();
    if (err && err.response && err.response.data instanceof Blob) {
      const blob = new Blob([err.response.data], {
        type: "application/json",
      });
      blob.text().then((errText) => {
        toast.error(JSON.parse(errText).message);
      });
    } else toast.error("Server error : ", err);
  }

  sortAscending(list:any[], sortBy:string){
    return list.sort((a:any,b:any)=> a[sortBy] < b[sortBy] ? -1 : 1)
  }

  sortDescending(list:any[], sortBy:string){
    return list.sort((a:any,b:any)=> a[sortBy] > b[sortBy] ? -1 : 1)
  }

  isDateFilter(filter: string) {
    return filter.toLowerCase().indexOf("date") > -1 && 
    filter.toLowerCase().indexOf("dates") === -1;
  }

  clone(obj:any){
    if(obj)
      return JSON.parse(JSON.stringify(obj));
    else
      return {};
  }

  getData(manager:VdxDropdownsDataManagerService, key:string){
    let data = manager.GetData();
    if(data)
      return manager.GetData()[key];
    else
      return new Array<any>();
  }
}
