<div class="row row-cols-4 gap-3 p-2">
  <div class="col" *ngFor="let list of lists">
    <vr-vdx-multi-selector-box
    [checkBoxLabel]="truncateLabel(list.displayName, 30)"
    [listDataHeader]="list.displayName"
    listItemLabelName="displayName"
    [listItemKeyName]="KEY"
    [listData]="list.options"
    [checked]="selectAll"
    [initValuesCsv]="SelectedValues"
    (onObjectsSelected)="onSelectedItems(list.displayName, $event)"
    [attr.title]="list.displayName"
  ></vr-vdx-multi-selector-box>
  
  </div>
</div>
