<div class="input-bg border-gray-200 pe-2">
    <mat-form-field class="email-input-container " style="width: 100%;background-color: #fff;" appearance="outline" >

       <mat-label style="font-weight: 700;">{{labelValue}}</mat-label>
       <div class="input-with-spinner input-container d-flex flex-row">

        <input [placeholder]="labelValue" type="text" matInput (keyup.enter)="addEmail(emailControl.value)" [formControl]="emailControl" [matAutocomplete]="auto">

        <mat-spinner *ngIf="isLoading$.value" diameter="20" mode="indeterminate"></mat-spinner>
      </div>

       <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addEmail($event.option.value)">
        <mat-option *ngFor="let email of filteredEmails" [value]="email">
          {{ email.userFormatedName }} - {{ email.emailID }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

    <mat-chip-listbox style="margin-top: -2vh;">
        <mat-chip *ngFor="let email of selectedEmails" color="accent"
          style="margin-right: 3px; background-color: #1f79ce;color: #fff">
          <mat-label style="color: #fff;margin-right: 5px;"> {{ email }} </mat-label>
          <button type="button" class="btn-close" style="color: #fff;font-size: 10px;background-color:#fff ;" aria-label="Close" 
          (click)="removeEmail( email )"></button>

        </mat-chip>
      </mat-chip-listbox>


  </div>
