import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './report-design/home/home.component';
import { ReportConfigurationComponent } from './report-design/main-new-report/report-configuration/report-configuration.component';
import { CustomNewReportComponent } from './report-design/custom-report/custom-new-report/custom-new-report.component';

const routes: Routes = [
  {path:'', component:HomeComponent},
  {path:'create', component: ReportConfigurationComponent, pathMatch: 'full' },
  {path:'edit',  component: ReportConfigurationComponent},
  {path:'custom-report',  component:CustomNewReportComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
