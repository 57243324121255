import { Component, Input } from '@angular/core';
import { AxiosResponse } from 'axios';
import { MsgboxService } from 'src/app/helpers/msgbox.service';
import { RbDataService } from 'src/app/services/rb-data.service';

@Component({
  selector: 'app-report-info',
  templateUrl: './report-info.component.html',
  styleUrls: ['./report-info.component.scss']
})
export class ReportInfoComponent {
  @Input() userDetials: any;
  @Input() userInfo:any;

  constructor(
    private rdb: RbDataService,
    private msgbox: MsgboxService,
  ) { }

  onToggleClick(event: Event) {
    event.stopPropagation();
  }
  
  onToggle(active: boolean ,userDetials: any) {
    this.userDetials.isGenerate = active;
    this.OnActive(userDetials, active);
  }

  OnActive(userDetials: any,isActive: boolean): void{
    this.rdb.UpdateReportConfiguration(userDetials.id,1,userDetials.isPublish,isActive).then(
      (response: AxiosResponse<any>) => {
        if (response.status === 200) {
          if (isActive) {
            this.msgbox.success("Generate Report when data is available is Activated!", "");
          } else {
            this.msgbox.warning("Generate Report when data is available is Deactivated!", "");
          }
        }
        else{
          console.log('Report status update failed');
        }
      },
    )
  }
}
