<div class="headers">
  <div class="header-title" >Schedule {{ index + 1 }}</div>
  <div class="header-active"> 
    Active: &nbsp;
    <app-checkbox-toggle 
      [checked]="schedule.isActive" 
      (onClick)="onReportActiveToggled($event,schedule.jobId)"
      type="secondary">
    </app-checkbox-toggle>
  </div>
  <div class="header-delete ml-auto cursor-pointer" (click)="onDelete.emit(index)" title="Delete">
    <!-- <img src="\assets\Icons\Delete.svg"> -->
     <mat-icon style="color: red;">delete</mat-icon>
  </div>
</div>

<div [class.inactive]="!schedule.isActive" class="d-flex flex-column box">
  
  <!-- DISTRIBUTE -->
  <div class="header-title">Distribution Criteria</div>
  <app-cron-expression
  [cronExpression]="this.schedule.cronExpression" 
  (cronExpressionChange)="onCronExpressionChange($event)">
  </app-cron-expression>
  <div style="padding: 10px;"></div>

  <!-- CRITERIAS -->
  <div class="header-title">Filter Criteria</div>
  <rb-filters-builder
    [criterias]="_filtersWithData"
    [filterValuesObject]="this.schedule.reportFilterCriteria">
  </rb-filters-builder> 
  <div style="padding: 10px;"></div>

  <!-- EMAIL -->
  <div class="d-flex align-items-center header-title" style=" display: flex; align-items: center;">
    Email Schedule &nbsp;
    <app-checkbox-toggle 
      [checked]="this.schedule.isMailRequired"
      (onClick)="this.schedule.isMailRequired = !this.schedule.isMailRequired" 
      type="secondary">
    </app-checkbox-toggle>
    
  </div>
  <div *ngIf="this.schedule.isMailRequired">
    <app-email-config
    [selectedSchdule]="schedule">
    </app-email-config>
  </div>
</div>
