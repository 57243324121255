import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RbDataService } from 'src/app/services/rb-data.service';

@Component({
  selector: 'app-report-categories',
  templateUrl: './report-categories.component.html',
  styleUrls: ['./report-categories.component.scss']
})
export class ReportCategoriesComponent implements OnInit {

  constructor(private rdb: RbDataService) { }

  @Input() selected: string = '';
  @Input() isAdd:boolean=false;
  @Output() selectedChange = new EventEmitter<string>();

  _categories: string[] = [];
  customCategory: string = '';
  showPopup: boolean = false;

  ngOnInit(): void {
    this.rdb.GetReportCategories().then(res => {
      this._categories = res.data;
      // Emit initial value to ensure it gets propagated to parent if necessary
      if (this.selected) {
        this.selectedChange.emit(this.selected);
      }
    });
  }

  selectCategory(category: string): void {
    this.selected = category;
    this.selectedChange.emit(this.selected);
  }

  togglePopup(): void {
    this.showPopup = true;
  }

  closePopup(): void {
    this.showPopup = false;
    this.customCategory = '';
  }

  addCustomCategory(): void {
    if (this.customCategory.trim()) {
      const newCategory = this.customCategory.trim();
      this._categories.push(newCategory);
      this.selectCategory(newCategory); // Select and emit the new category
      this.closePopup();
    }
  }
}
