<p-table
  [value]="data"
  [columns]="cols"
  [reorderableColumns]="true"
  (onRowReorder)="onRowReordered()"
  responsiveLayout="scroll"
>
  <!-- <ng-template pTemplate="header" let-columns>
    <tr>
      <th style="width: 3rem;"></th>
      <th *ngFor="let col of columns">
        {{col.header}}
      </th>
    </tr>
  </ng-template> -->
  <ng-template
    pTemplate="body"
    let-rowData
    let-columns="columns"
    let-index="rowIndex"
  >
    <tr [pReorderableRow]="index">
      <td (click)="onRemove(rowData[columns[0].field])" class="text-start">
        <span class="pi pi-arrow-circle-left cursor-pointer"></span>
      </td>
      <td>
        {{rowData[columns[1].field]}} 
      </td>
      <td style="width: 20px;">
        <span class="pi pi-bars" pReorderableColumn></span>
      </td>
    </tr>
  </ng-template>
</p-table>
