<div
  *ngIf="_criterias && _criterias.length > 0"
  class="d-flex flex-column gap-2 justify-content-start w-100"
>
 
  <vr-vdx-filters-builder
  [filters]="_criterias"
  [inputPayload]="filterValuesObject"
  (onDataChagned)="onFilterValueSet.emit($event)"
  ></vr-vdx-filters-builder>

</div>
