import { UIListItem } from "src/app/helpers/app-models";
import { MainAppUtils } from "src/app/helpers/main-app-utils";

export class ReportConfigurationHelper {

  _mainUtils  = new MainAppUtils();

  public convertCsvToUiListItems(csvLabels: string, csvValues: string): UIListItem[] {
    let labels = csvLabels.split(",");
    let values = csvValues.split(",");
  
    if (labels.length !== values.length) {
      console.error("Labels and Values have mismatched count");
      return [];
    }
  
    return labels.map((label, index) => ({
      id: values[index], // Use actual criteria value as ID instead of index
      name: label,
      value: values[index]
    }));
  }
  

  public convertUiListToCsv(list: UIListItem[], key: string) {
    return list.map((o: any) => o[key]).join(",");
  }
}
