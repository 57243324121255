<div>
  <label class="label-header">Report Access<label class="text-danger fw-bold fs-6"  >*</label>&nbsp;
    <input type="checkbox" [checked]="areAllRolesSelected" (change)="toggleSelectAll($event)" />
    <strong style="padding-left: 10px;">Selected : {{ selectedCount }}</strong>
  </label>
</div>
<div class="role-mapping">
  <div
    *ngFor="let role of _userRoles"
    [class.selected]="isSelected(role.value)"
    (click)="toggleSelection(role.value)"
    class="role-item">
    {{ role.name }}
  </div>
</div>
