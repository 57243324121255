<div class="menu-header">
  <span class="label-header" >Select Category<label class="text-danger fw-bold fs-6"  >*</label></span>
  <!-- <span class="right-text">Report Format</span> -->
</div>

<div class="row-container">
  <div class="menu-container">
    <div class="single-select-menu">
      <div
        *ngFor="let category of _categories"
        (click)="selectCategory(category)"
        [class.selected]="category === selected"
        class="menu-item"
      >
        {{ category }}
      </div>
    </div>
  </div>

  <!-- <div class="format-container">
    <div class="format">
      <img src="/assets/Icons/Excel.svg" >&nbsp; Excel
    </div>
  </div> -->
</div>
