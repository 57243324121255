<div class="surgicenter">
  <div class="surgicenter-action-center">
    <app-search-box class="searchbox"></app-search-box>
    <app-button buttonclass="secondary" class="return-btn" (click)="close()">Return</app-button>
    <app-button buttonclass="primary" class="add-btn" (click)="addItems()">Add</app-button>
  </div>

  <div cdkDropListGroup class="surgicenter-dd-full-container mt-2">
    <!-- Master List Container -->
    <div class="surgicenter-dd-container" style="margin-right:5%;">
      <div class="surgicenter-tbl-head surgicenter-table-grid">
        <div style="font-size: 14px; color: #fff; text-align: center; font-weight: 300;">
          Master List
        </div>
      </div>

      <div class="surgicenter-dd-main-container" cdkDropList (cdkDropListDropped)="onDrop($event, 'masterListLocal')">

        <!-- Group by Category for Master List -->
        <div *ngFor="let category of masterListCategories">
          <!-- Category Header -->
          <div class="category-header" (click)="toggleCategoryExpansion(category)">
            <b><span>{{category}}</span> &nbsp;</b>
            <!-- <mat-icon>{{ expandedCategories[category] ? 'expand_more' : 'chevron_right' }}</mat-icon> -->
            <i class="bi" [ngClass]="expandedCategories[category] ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
          </div>

          <!-- Items under each category -->
          <div *ngIf="expandedCategories[category]">
            <div *ngFor="let item of getItemsByCategory('masterListLocal', category)" 
              class="surgicenter-table-grid surgicenter-tbl-items"
              (click)="toggleSelection(item, 'masterListLocal', $event)"
              [class.selected-item]="isSelected(item, 'masterListLocal')"
              cdkDrag [cdkDragData]="item"
              [cdkDragDisabled]="isInUserSelectionList(item)"
              style="BORDER-BOTTOM: 1px solid gray;">
              <span>{{item.name}}</span>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="move-btn-container">
      <app-button [image]="'../../../../assets/Icons/Double arrow white.svg'"
                  buttonclass="primary"
                  (click)="moveSelectedItems('masterListLocal', 'userSelectionList')"></app-button>
      <app-button [image]="'../../../../assets/Icons/Double arrow white_reverse.svg'"
                  buttonclass="primary"
                  (click)="moveSelectedItems('userSelectionList', 'masterListLocal')"></app-button>
    </div>

    <!-- Selected List Container -->
    <div class="surgicenter-dd-container" style="margin-left:5%;">
      <div class="surgicenter-tbl-head surgicenter-table-grid">
        <div style="font-size: 14px; color: #fff; text-align: center; font-weight: 300;">
          Selected List
        </div>
      </div>

      <div class="surgicenter-dd-main-container" cdkDropList (cdkDropListDropped)="onDrop($event, 'userSelectionList')">

        <!-- Group by Category for Selected List -->
        <div *ngFor="let category of userSelectionCategories">
          <!-- Category Header -->
          <div class="category-header" (click)="toggleCategoryExpansion(category)">
            <b><span>{{category}}</span> &nbsp;</b>
            <!-- <mat-icon>{{ expandedCategories[category] ? 'expand_more' : 'chevron_right' }}</mat-icon> -->
            <i class="bi" [ngClass]="expandedCategories[category] ? 'bi-chevron-up' : 'bi-chevron-down'"></i>
          </div>

          <!-- Items under each category -->
          <div *ngIf="expandedCategories[category]">
            <div *ngFor="let item of getItemsByCategory('userSelectionList', category)" 
              class="surgicenter-table-grid surgicenter-tbl-items"
              (click)="toggleSelection(item, 'userSelectionList', $event)"
              [class.selected-item]="isSelected(item, 'userSelectionList')"
              cdkDrag [cdkDragData]="item"
              style="display:flex; BORDER-BOTTOM: 1px solid gray;">
              <mat-icon style="font-size: 20px;">toc</mat-icon>
              <span style="width: 80%; padding-left: 2%;">{{item.name}} </span>
              <mat-icon style="font-size: 20px;" (click)="removeItem(item)">cancel</mat-icon>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</div>
