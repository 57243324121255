<vr-vdx-multi-selector-box
    [checkBoxLabel]="param_checkBoxLabel"
    [listDataHeader]="param_listDataHeader"
    [listItemLabelName]="param_listItemLabelName"
    [itemTextDimKeyName]="param_itemTextDimKeyName"
    [listItemKeyName]="param_listItemKeyName"
    [listData]="list"
    [initValuesCsv]="initValuesCsv"
    [checkBoxLabelCss]="param_checkBoxLabelCss"
    (onObjectsSelected)="onSelectedItems($event)"
></vr-vdx-multi-selector-box>
