import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { RbDataService } from 'src/app/services/rb-data.service';

@Component({
  selector: 'app-role-mapping',
  templateUrl: './role-mapping.component.html',
  styleUrls: ['./role-mapping.component.scss']
})
export class RoleMappingComponent implements OnInit {

  _userRoles: { name: string, value: string }[] = [];
  selectedRoles: Set<string> = new Set<string>();
  areAllRolesSelected: boolean = false;
  selectedCount: number = 0;
  private _initialSelectedRoles: string = '';

  // Accept comma-separated roles from the parent component
  @Input() set selected(roles: string) {
    if (roles) {
      this._initialSelectedRoles = roles; // Save the initial roles for later auto-selection
      this.autoSelectRoles(roles); // Try auto-selecting if roles are already fetched
    }
  }

  // Output to emit the updated roles back to the parent component
  @Output() selectedChange = new EventEmitter<string>();

  constructor(private rdb: RbDataService) { }

  ngOnInit(): void {
    if (this._userRoles.length === 0) {
      this.rdb.GetAllUserRoles().then((res) => {
        if (res.data) {
          this._userRoles = this.convertToObjects(res.data);
          // After fetching roles, apply any initial selections passed from the parent
          if (this._initialSelectedRoles) {
            this.autoSelectRoles(this._initialSelectedRoles);
          }
          this.updateAllRolesSelection();
        }
      }).catch(err => console.error(err));
    }
  }

  convertToObjects(rolesArray: string[]): { name: string, value: string }[] {
    return rolesArray.map((m: string) => ({ name: m, value: m }));
  }

  isSelected(value: string): boolean {
    return this.selectedRoles.has(value);
  }

  toggleSelection(value: string): void {
    if (this.selectedRoles.has(value)) {
      this.selectedRoles.delete(value);
    } else {
      this.selectedRoles.add(value);
    }
    this.updateAllRolesSelection();
    this.emitSelectedRoles(); // Emit updated roles
  }

  toggleSelectAll(event: Event): void {
    const target = event.target as HTMLInputElement;
    if (target.checked) {
      this.selectedRoles = new Set(this._userRoles.map(role => role.value));
    } else {
      this.selectedRoles.clear();
    }
    this.updateAllRolesSelection();
    this.emitSelectedRoles(); // Emit updated roles
  }

  updateAllRolesSelection(): void {
    this.areAllRolesSelected = this._userRoles.length > 0 && this._userRoles.every(role => this.selectedRoles.has(role.value));
    this.selectedCount = this.selectedRoles.size;
  }

  // Automatically select roles based on the comma-separated string passed from the parent
  autoSelectRoles(roles: string): void {
    const rolesArray = roles.split(',').map(role => role.trim());
    // Only add roles that are present in _userRoles to avoid errors
    this.selectedRoles = new Set(
      rolesArray.filter(role => this._userRoles.some(userRole => userRole.value === role))
    );
    this.updateAllRolesSelection();
    this.emitSelectedRoles(); // Emit updated roles if needed
  }

  // Emit the selected roles as a comma-separated string
  emitSelectedRoles(): void {
    const selectedRolesString = Array.from(this.selectedRoles).join(',');
    this.selectedChange.emit(selectedRolesString);
  }
}
