export const DATE_FORMAT_INPUT = 'yyyy-MM-dd'
export const DATE_FORMAT_SAVE_INPUT = 'MM/dd/yyy'
export const DATETIME_VIEW_SAVE_FORMAT = 'MM/dd/yyy h:mm:ss a'
export const DATE_VIEW_FORMAT_DD_MMM_YYYY = 'dd/MMM/yyyy'

export const DEFAULT_PASS_PATTERN = 'PDX_{MMDDYY}';

export enum MainMenuItems {
    REPORT_TYPE = 'Report Type',
    COLUMNS = 'Columns',
    FILTERS = 'Filters',
    EXCEL_TEMPLATE = 'Excel Templates',
    SCHEDULERS = 'Schedule',
    DISTRIBUTION = 'Email Report',
    EXIT = 'Exit'
}

export enum Schedules{
    HOURLY = 'hourly',
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly'
}

export enum WeekdayUiOption {
    checkbox = 'checkbox',
    dropdown = 'dropdown'
}

export enum ScheduleDetails {
    hourly_details_1 = 'hourly_details_panel_1',
    hourly_details_2 = 'hourly_details_panel_2',
    daily_details_1 = 'daily_details_panel_1',
    daily_details_2 = 'daily_details_panel_2',
    weekly_details_1 = 'weekly_details_panel_1',
    monthly_details_1 = 'monthly_details_panel_1',
    monthly_details_2 = 'monthly_details_panel_2'
  }

export enum appEnv {
    DEV = 'DEV',
    PROD = 'PROD'
}