<div style="padding: 20px;">
    <table class="audit-table">
      <thead>
        <tr>
          <th>Success Schedules</th>
          <th>Failed Schedules / Downloads</th>
          <th>Successfully Downloded</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ userDetials?.successSchdeule }}</td>
          <td>{{ userDetials?.failedReport }}</td>
          <td>{{ userDetials?.exportReport }}</td>
        </tr>
      </tbody>
    </table>
  </div>
  