import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UIListItem } from 'src/app/helpers/app-models';

@Component({
  selector: 'app-filter-criteria',
  templateUrl: './filter-criteria.component.html',
  styleUrls: ['./filter-criteria.component.scss']
})
export class FilterCriteriaComponent implements OnInit, OnChanges {

  @Output() onDataChanged = new EventEmitter<Array<UIListItem>>();
  @Input() isselect = true;
  @Input() actualCriteria: UIListItem[] = [];
  @Input() selectedCriteria: UIListItem[] = [];

  areAllRolesSelected: boolean = false;

  ngOnInit(): void {
    this.updateSelection();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['actualCriteria'] || changes['selectedCriteria']) {
      this.updateSelection();
    }
  }

  private updateSelection(): void {
    console.log("Actual:", this.actualCriteria.length);
    console.log("Selected:", this.selectedCriteria.length);

    if (this.isselect) {
      if (this.selectedCriteria.length === 0 || this.selectedCriteria.length === this.actualCriteria.length) {
        this.selectedCriteria = [...this.actualCriteria]; // Select all if empty
      }
    } else {
      this.selectedCriteria = [];
    }
    this.updateSelectAllState();
  }

  get selectedCount(): number {
    return this.selectedCriteria.length;
  }

  toggleSelectAll(event: any): void {
    this.areAllRolesSelected = event.target.checked;
    this.selectedCriteria = this.areAllRolesSelected ? [...this.actualCriteria] : [];
    this.onDataChanged.emit(this.selectedCriteria);
  }

  toggleSelection(role: UIListItem): void {
    const index = this.selectedCriteria.findIndex(item => item.id === role.id);
    if (index === -1) {
      this.selectedCriteria.push(role);
    } else {
      this.selectedCriteria.splice(index, 1);
    }
    this.updateSelectAllState();
    this.onDataChanged.emit(this.selectedCriteria);
  }

  isSelected(role: UIListItem): boolean {
    return this.selectedCriteria.some(item => item.id === role.id);
  }

  private updateSelectAllState(): void {
    this.areAllRolesSelected = 
      this.selectedCriteria.length > 0 &&
      this.selectedCriteria.length === this.actualCriteria.length;
  }
}
