import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-multiselect-list-item',
  templateUrl: './multiselect-list-item.component.html',
  styleUrls: ['./multiselect-list-item.component.scss']
})
export class MultiselectListItemComponent  implements OnInit {

  constructor() { }
  @Input() key :string = '';
  @Input() value :string ='';
  @Input() object :any;
  @Input() isSelected = false;
  @Input() itemTextDimKeyName:string = '';
  @Output() onUserClick = new EventEmitter<any>();
  
  _itemWidth = 300;
  @Input() set itemWidth (val:number){
    if(val > 300){
      this._itemWidth = val;
      this.updateStyle();
    }
    else
      this._itemWidth = 300;
  }


  _style = '';

  ngOnInit(): void {
    this.updateStyle();
  }

  updateStyle(){
    this._style = `
  font-size: 1rem;
  width: ${this._itemWidth}px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  `;

  //console.log(this.itemTextDimKeyName);
    if(this.itemTextDimKeyName && this.itemTextDimKeyName != ''){
      if(this.object[this.itemTextDimKeyName])
        this._style = this._style + 'color:gray;font-style: italic;'
    }

  }

  raiseOnUserClick(){
    this.isSelected = !this.isSelected;
    this.onUserClick.emit({object:this.object, flag:this.isSelected});
  }

}
