import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'rb-config-panel',
  templateUrl: './config-panel.component.html',
  styles: ['.label-colors{color: var(--base-primary-dark-text-color) !important;line-height: 20px!important;}'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfigPanelComponent implements OnInit{

  
  @Input() header : string = 'Title';
  @Input() cssStyle : string = '';
  constructor() { }

  ngOnInit(): void {
  }

}
