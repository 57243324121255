<div style="display: flex; justify-content: center; padding: 20px;">
  <div class="border-search d-flex col-sm-7 mr-3 p-1">
    <div class="search-divider">
      <mat-icon title="Search">search</mat-icon>
    </div>
    <input type="text" class="search-input" placeholder="Search Reports" [(ngModel)]="searchTerm">
    <em class="material-icons cursor"
        *ngIf="searchTerm !== null && searchTerm !== ''"
        (click)="clearSearch()">close</em>
  </div>
</div>

<app-tabs [tabData]="tabList" [activeTab]="selectedTab" (selectedTab)="getTabData($event)"></app-tabs>

<div *ngIf="selectedTab === selectedTab">
  <div class="report-content">
    <div class="report-grid">
      <div class="report-card" *ngFor="let report of filteredReports">
        <div class="report-details">
          <div class="report-header">
            <div class="report-name">{{ report.title }}</div>
            <div class="report-icons">
              <img src="\assets\Icons\Excel.svg" alt="Excel icon">
            </div>
          </div>
          <div class="report-description" style="height: 100px; cursor: pointer;">
            <span [matTooltip]="report.description.length > 150 ? report.description : ''">
              {{report.description.length > 150 ? (report.description | slice: 0:150) + '...' : report.description}}
            </span>
          </div>
          <div class="report-footer">
            <button
              style="color:#1f79ce; border: 1px solid #1f79ce;"
              *ngIf="report.slaveReports.length > 0"
              mat-button
              [matMenuTriggerFor]="menu">
              Update ({{ report.slaveReports.length }})
              <mat-icon>arrow_drop_down</mat-icon>
            </button>

            <mat-menu #menu="matMenu" class="menus">
              <button mat-menu-item *ngFor="let slaveReport of report.slaveReports" (click)="onProceed(slaveReport.id, slaveReport.isPublish ? 1 : 2)" style="max-height: 10vh !important;">
                <div class="icon-row" style="margin-right: 1.5vw;">
                  <ng-container *ngIf="slaveReport.isPublish; else draftIcon">
                    <button  matTooltip="Published" style="width: 12px;height: 8px;border: none;background-color: green;border-radius: 10px;"></button>
                  </ng-container>
                  <ng-template #draftIcon>
                    <button  matTooltip="Draft" style="width: 12px;height: 8px;border: none;background-color: gray;border-radius: 10px;"></button>
                  </ng-template>
                  <span style="margin-right: 1.5vw;" 
                    [matTooltip]="slaveReport.title.length > 30 ? slaveReport.title: ''">
                    {{ slaveReport.title.length > 30 ? (slaveReport.title | slice:0:30) + '...' : slaveReport.title }}
                  </span>
                  &nbsp;
                  <img  src="\assets\Icons\Excel.svg" alt="Excel icon" class="icon-right">
                </div>
              </button>
            </mat-menu>

            <button
              class="btn vf-g-btn-primary"
              style="background-color: #1f79ce; color:white;padding: 7px;"
              (click)="onProceed(report.id)">
              Create
            </button>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
