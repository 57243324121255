import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AxiosResponse } from 'axios';
import { MenuItem } from 'primeng/api';
import { reportDetails } from 'src/app/helpers/app-models';
import { MsgboxService } from 'src/app/helpers/msgbox.service';
import { RbDataService } from 'src/app/services/rb-data.service';
import { ConfirmationDialogComponent, DialogData } from 'src/app/shared/confirmation-dialog/confimation-dialog.component';

@Component({
  selector: 'app-preview',
  templateUrl: './preview.component.html',
  styleUrls: ['./preview.component.scss']
})
export class PreviewComponent {
  @Input() report: any;
  @Input() viewType = "small";
  @Output() CloseDetials = new EventEmitter<any>();
  @Input() userInfo: any;
  @Output() editReport = new EventEmitter<{ reportId: number, action: number }>();
  items: MenuItem[];
  originalIsActive: boolean; 
  Loader:boolean=false;

  reportdetails: reportDetails[] = [];

  tabList = [
    { Htext: "Details" },
    { Htext: "Schedules" },
    { Htext: "Audit" }
  ];
  selectedTab: string = 'Details';

  constructor(private rdb: RbDataService,
    private router: Router,
    private msgbox: MsgboxService,
    private dialog: MatDialog,
  ) {}

  onNavigateToHome(): void {
    if (this.reportdetails[0].isActive !== this.originalIsActive) {
      window.location.reload();  
    } else {
      this.CloseDetials.emit(false);  
    }
  }

  ngOnInit(): void {
    this.Loader = true;
    this.OnDetails(this.userInfo);
  }

  CloseView(): void {
    if (this.reportdetails[0].isActive !== this.originalIsActive) {
      window.location.reload();  
    } else {
      this.CloseDetials.emit(false);  
    }
  }

  OnDetails(userInfo: any): void {
    this.rdb.GetReportDetails(userInfo.id, userInfo.published).then(
      (res: any) => {
        this.reportdetails = res.data;
        this.Loader=false;
        this.items = [
          { label: 'Statistics', command: () => this.onNavigateToHome() },
          //{ label: 'Statistics', routerLink: '/' },
          {label: this.reportdetails[0].title},
          {label:'View'}
        ];
        this.originalIsActive = this.reportdetails[0].isActive; 
      },
      (error: any) => {
        console.error('Error fetching reports:', error);
        this.Loader = false;
      }
    );
  }
  getTabData(event: any): void {
    this.selectedTab = event;
  }
  
  OnEditReport(reportId: number, action: number): void {
    if (reportId && action) {
      this.editReport.emit({ reportId, action }); 
      this.router.navigate(['/edit'], { queryParams: { reportId : btoa(reportId.toString()) , action : btoa(action.toString()) } });
    } else {
      console.error('Report ID or action is undefined');
    }
  }

  getNumberOfSchedules(report: reportDetails): number {
    return report.schedule.length;
  }

  onToggleClick(event: Event) {
    event.stopPropagation();
  }

  onReportActiveToggled( report: any) {
      this.onPublish(report.id); 
  }

  onPublish(reportId: number) {
    this.rdb.UpdateReportStatus(reportId).then((res) => {
      if (res.data === true) {
      this.reportdetails[0].isActive = !this.reportdetails[0].isActive;
      this.msgbox.success(
        this.reportdetails[0].isActive ? 'Report Published Successfully' : 'Report Unpublished Successfully',
        ''
      );
      } else {
      this.msgbox.error('Report Publish/Unpublish Failed', '');
      }
    });
  }
 
}
