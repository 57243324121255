import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FilterConfig,FilterTypeDefintion } from 'src/app/helpers/app-models';
import { MainAppUtils } from 'src/app/helpers/main-app-utils';

@Component({
  selector: 'rb-filters-builder',
  templateUrl: './filters-builder.component.html',
  styleUrls: ['./filters-builder.component.scss']
})
export class FiltersBuilderComponent  implements OnInit {
  constructor() {}
  
  @Input() filterValuesObject: any = null;

  _criterias = new Array<FilterTypeDefintion>();
  @Input() set criterias(val: FilterTypeDefintion[]) {
    this._criterias = val;
  }

  @Output() onFilterValueSet = new EventEmitter<FilterConfig>();

  ngOnInit(): void {
    //console.log('init',this.filterValuesObject);
  }

  isDateFilter(filter: string) {
    return new MainAppUtils().isDateFilter(filter);
  }

}
