export enum PasswordTypes {
    dynamic = "dynamic",
    custom = "custom",
    default = "default",
  }
  export class UserDetails {
    "status": string; // "Success",
    "OrganizationName": string; // "PROMUS DIAGNOSTICS, LLC",
    "FormattedDisplayName": string; // "Mr. Gayakwad, Pavan",
    "statusmessage": string; // "",
    "userguid": string; // "5D2923BB-71CC-4BDA-A9DF-14EF1C15CB06",
    "role": string; // null,
    "location": string; // null,
    "userorg": string; // "1148AB92-7850-40DF-B53B-F43AD7E13E35",
    "ownerorg": string; // null,
    "tokenString": string; // null,
    "additionalinfo": string; // null,
    "loginid": string; // "pavan.gayakwad@starmarkit.com",
    "password": string; // null,
    "tokenurl": string; // null,
    "guidinfourl": string; // null,
    "organizationid": string; // "45341",
    //"userid": string; // "1900347",
    "rolename": string; // "Super Admin",
    "ModifiedBy": string; // null,
    "sourceapplication": string; // null
    "AssociationType": string;
    "apiHeaders": {};
  }
  export class FlatTabItem {
    constructor(_id: string, _label: string, _icon: string, _info: string) {
      this.label = _label;
      this.id = _id;
      this.icon = _icon;
      this.info = _info;
    }

    label: string = "";
    id: string = "";
    icon: string = "";
    info: string = "";
  }

  export class TreeViewData {
    label: string = "";
    data: any;
    status: string = "";
    checked: boolean = false;
    children = new Array<TreeViewData>();
    expanded: boolean = false;
    parentName: string = "";
    visible = true;

    constructor(
      _parentName: string,
      _lable: string,
      _data: any,
      _status: string,
      _checked: boolean
    ) {
      this.parentName = _parentName;
      this.label = _lable;
      this.data = _data;
      this.status = _status;
      this.checked = _checked;
    }
  }

  export class UIListItem {
    id: string = "";
    name: string = "";
    value: string = "";
  }

  export class RBOrganizationReportsDefinition {
    isPublish: boolean | undefined;
    reportId: number = 0;
    organizationId: number = 0;
    title: string = '';
    description: string = '';
    category: string = '';
    //storedProcedure: string = '';
    templateFile: string = '';
    //namedRange: string = '';
    criteria: string = '';
    //version: string = '';
   // reportGroup: string = '';
    //fileType: string = '';
    //groupSequence: string = '';
    //reportSequence: string = '';
    //detailQuery: string = '';
    //outputFieldsQuery: string = '';
    isactive: string = '';
    deploymentKey: string = '';
    //includeHeader: string = '';
    isAsynchronousReport: string = '';
    //approxGenerationTime: string = '';
    connectionName: string = '';
    jobId: string = '';
    //showMaxRecord: string = '';
    //maxDays: string = '';
    criteriaDisplayNames: string = '';
    masterColumns: string = '';
    slavecolumn: string = '';
    parentReportId: string = '';
    reportType: string = '';
    isGenerate:boolean=false;
    notifyOnlyOnData:boolean=false;
    NotifyBlankAttachment :boolean = false;
    criteriaValue:string = "";
    //masterDisplayName: string = "";
    //slaveDisplayName: string = "";
    action: string = "";
    masterCriteria: string="";
    masterCriteriaDisplayName :string ="";
    timezone: string = '';
    roles:string='';
    id:number=0;
    masterCategory:string='';
    slaveCategory:string='';
  }

  export class RBUserscheduledReportMaster {
    jobName: string = '';
    reportId: number = 0;
    reportName: string = '';
    fileName: string = '';
    reportParms: string = '';
    reportFilterCriteria: any = '';
    reportColumns:string = '';
    cronExpression: string = '';
    userEmail: string = '';
    createdDate: string = '';
    status: string = '';
    connectionName: string = '';
    isActive: boolean = false;
    deploymentKey: string = '';
    modifiedDate: string = '';
    isMailRequired :boolean = false;
    emailTo: string = '';
    emailCC: string = '';
    emailBCC: string = '';
    emailSubject: string = '';
    reportLockPasswordType: string = '';
    reportLockPasswordData: string = '';
    emailBody: string = '';
    orgId: number = 0;
    jobId: number = 0;
    notifyOnlyOnData: boolean = false;
    action: string = "";
    includeDateInSubject : boolean = false;
    onBusinessDaysOnly:boolean = false;
    NotifyBlankAttachment :boolean = false;
    enablePasswordToEmail:boolean=false
  }

  export class KeyValueItem {
    constructor(keyName:string, val:any){
      this.key = keyName;
      this.value = val;
    }
    key:string = '';
    value:any = {};
  }

  export class RBreportsDefinition{
    isPublish :boolean=true;
    reportId: number = 0;
    formattedCriteriaDisplayNames:string='';
    organizationId: number = 0;
    title: string = '';
    description: string = '';
    category: string = '';
    storedProcedure: string = '';
    templateFile: string = '';
    namedRange: string = '';
    criteria: string = '';
    version: string = '';
    reportGroup: string = '';
    fileType: string = '';
    groupSequence: string = '';
    reportSequence: string = '';
    detailQuery: string = '';
    outputFieldsQuery: string = '';
    isActive: boolean =false;
    isGenerate:boolean=false;
    notifyOnlyOnData:boolean=false;
    deploymentKey: string = '';
    includeHeader: string = '';
    isAsynchronousReport: string = '';
    approxGenerationTime: string = '';
    connectionName: string = '';
    jobId: string = '';
    showMaxRecord: string = '';
    maxDays: string = '';
    criteriaDisplayNames: string = '';
    masterColumns: string = '';
    parentReportId: number = 0;
    reportType: string = '';
    criteriaValue:string = "";
    showDetails: boolean | undefined;
    cron:string='';
    password:string='';
    email:string='';
    rolename:string='';
    schedule: Schedule[] = [];
  }
  export class Schedule1 {
    jobID:number = 0;
    cron: string = '';
    passwordType: string = '';
    password: string = '';
    email: string = '';
    notifyOnlyOnData: any;
    notifyBlacnkAttahcment :any;
    showPassword:boolean=false;
  }

  export class MessagePack {
    to: String = '';
    message: any;
}

export class DownloadReportRequestPayloadTemplate {
  // "UserId": string;
   "ReportId": string;
   "PayerIds": string;
   "FacilityIds":string;
   "Casetypes": string;
   "SignedPathologistIds": string;
   "DateFrom": string;
   "DateTo": string;
   "DateFilterCategory": string;
   "OrganizationId": string;
   "OrganizationGUID": string;
   "OrganizationIds":string;
   "LoginName": string;
   "LoggedinAccountId": string;
   "DeploymentKey": string;
   "UserGUID": string;
   "FacilityOrgIds":string;
   "FacilityStateIds":string;
   "SignedByLocations":string;
   "CptCodes":string;
   "calluserids":string;
   "clientbillstatus":string;
   "CPTCodeModifier":string;
   "RequestIdentifier":string;
}

export class FilterConfig {
  payload : any = new DownloadReportRequestPayloadTemplate();
  datesBag :any; //datesBag is a json Object not array & its like {"signoutdate":['23/12/2022','30/12/2022']}
  validationKeys : Map<string,string> =new Map<string,string>(); //as [payloadKey, userFriendlyErrorMessage]
}


////////////new

  export class allReports{
    id:string='';
    reportName:string='';
    menu:string='';
    schedules:string='';
    createdDate:string='';
    published:boolean=false;
    isActive:boolean=false;
  }

  export class reportDetails{
    id:number=0;
    reportId:number=0;
    title:string='';
    category :string='';
    description:string='';
    criteriaDisplayNames:string='';
    masterColumns:string='';
    roles :string='';
    isGenerate :boolean=false;
    isPublish:boolean=false;
    successSchdeule :string='';
    exportReport :string='';
    failedReport :string='';
    schedule: Schedule[] = [];
    isActive: boolean = false;
  }

  export class Schedule {
    jobID:string='';
    cron: string = '';
    //passwordType: string = '';
    password: string = '';
    emailToCount: number = 0;
    emailBCCCount: number = 0;
    emailCCCount: number = 0;
    emailTo:string='';
    emailCC:string='';
    emailBCC:string='';
    notifyOnlyOnData: boolean=false;
    //notifyBlacnkAttahcment :any;
    //showPassword:boolean=false;
  }


  export class FilterTypeDefintion {

    constructor(label:string, type:string){
        this.filterLabel = label;
        this.filterType = type;
    }
    filterType:string= '';
    filterLabel :string ='';
    userValue : string = '';
}


export class RBReports{
  id:number=0;
  reportId:number=0;
  title:string='';
  category :string='';
  description:string='';
  isPublish:boolean=false;
  parentReportId:string='';
  slaveReports :SlaveReports[]=[];
}
export class SlaveReports{
  id:number=0;
  reportId:number=0;
  title:string='';
  category :string='';
  description:string='';
  isPublish:boolean=false;
  parentReportId:string='';
}

export class RBCustomReportsDefinition {
  id:string='';
  reportId:string='';
  isMaster:boolean=false;
  isactive: boolean=false ;
  isOrganization: boolean=false ;
  title: string = '';
  description: string = '';
  category: string = '';
  storedProcedure: string = '';
  templateFile: string = '';
  namedRange: string = '';
  parameterNames:string='';
  criteria: string = '';
  criteriaDisplayNames :string='';
  version: string;
  roles:string='';
  isAsynchronousReport: boolean=false;
  isMultisheet :boolean=false;
  approxGenerationTime: string = '';
  connectionName: string = '';
  maxDays: string = '';
  columns:string='';
  report:string='';
action: string;
slavecolumn:string='';
}
