import { Component, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { MenuItem } from 'primeng/api';
import { AppHelperService } from 'src/app/helpers/app-helper';
import { FilterTypeDefintion, RBCustomReportsDefinition, RBOrganizationReportsDefinition, RBUserscheduledReportMaster, UIListItem } from 'src/app/helpers/app-models';
import { MsgboxService } from 'src/app/helpers/msgbox.service';
import { RbDataService } from 'src/app/services/rb-data.service';
import { ConfirmationDialogComponent, DialogData } from 'src/app/shared/confirmation-dialog/confimation-dialog.component';
import { AxiosResponse } from 'axios';
import { ReportConfigurationHelper } from '../../main-new-report/report-configuration/report-configurations-helper';
import { MainAppUtils } from 'src/app/helpers/main-app-utils';
import { VdxDropdownsDataManagerService } from '../../vdx-filters-builder/vdx-helpers/vdx-dropdowns-data-manager.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-custom-new-report',
  templateUrl: './custom-new-report.component.html',
  styleUrls: ['./custom-new-report.component.scss']
})
export class CustomNewReportComponent {

  items: MenuItem[];
  reportForm: FormGroup;
  selectedValue: string | null = null;
  _templateFilesSelectedToUpload: File | undefined;
  templateFileUploader: ElementRef | undefined;
  _selectedReportForSave :RBCustomReportsDefinition =new RBCustomReportsDefinition();
  _deletedScheduleJobIds: Array<number> = new Array<number>();
  _schedules: Array<RBUserscheduledReportMaster> = new Array<RBUserscheduledReportMaster>();
  _filtersAndData: FilterTypeDefintion[] = new Array<FilterTypeDefintion>();
  _selectedReport: RBOrganizationReportsDefinition = new RBOrganizationReportsDefinition();
  utils: MainAppUtils = new MainAppUtils();
  showloader:boolean=false
  _loadSchedules = true;
  _showReportDetails = true;
  _master_criteria = new Array<UIListItem>();
  _slave_criteria = new Array<UIListItem>();
  public _helper = new ReportConfigurationHelper();
  private _min = 1; 
  private _max = 365; 
  private _days = 30; 
  list = [
    { value: 0, label: '0 (Multi sheet)' },
    { value: 1, label: '1 (Normal Reports)' },
    { value: 2, label: '2 (XLSX format files)' },
    { value: 4, label: '4 (CSV format files)' },
    { value: 5, label: '5 (XLSX using Grape)' }
  ];

  constructor(private msgbox: MsgboxService,
    private rdb: RbDataService,
    private router: Router,
    private toastr: ToastrService,
    private appHelper: AppHelperService,
    private dialog: MatDialog,    
    private vdxData: RbDataService,
    private vdxDdlData: VdxDropdownsDataManagerService,
    private fb: FormBuilder,
    private http: HttpClient)
    {
    this.reportForm = this.fb.group({
      Title: [''], 
      Description: [''], 
      spname: [''], 
      namedrange: [''], 
      parametername:[''],
      ismaster: [true],
      isactive:[true],
      isasynchronous:[],
      apprGenerationTime:[],
      ismultisheet:[],
      connectionname:[''],
      selectedVersion: (null), 
      Columns:[],
      reportType: ['master'],        
      MaxDays: [this._days, [Validators.required, Validators.min(this._min), Validators.max(this._max)]], 
      isOrganization:[false]
    });      
  }

  ngOnInit(): void {
    this.items = [
      {label:'Statistics', routerLink: '/' },
      {label:'Custom Report'},
      {label:'Create'}
    ]; 
    this.LoadReportDetails();
  }

  private convertJsonToUiListItems(jsonData: { [key: string]: string }): UIListItem[] {
    let list: UIListItem[] = [];
    let keys = Object.keys(jsonData);
    let values = Object.values(jsonData);

    for (let i = 0; i < keys.length; i++) {
      list.push({
        id: i.toString(),
        name: values[i], // Label (from the right side of the JSON)
        value: keys[i]   // Value (from the left side of the JSON)
      });
    }
    return list; // Optionally sort the list if needed
  }

  LoadReportDetails() {
    fetch('assets/DefaultCriteria.json')
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        const masterCriteria = this.convertJsonToUiListItems(data);
        this._master_criteria = masterCriteria;
        this.publishSlaveFilterData(this._master_criteria);
        const mockCriteria = Object.keys(data).join(','); 
        this.vdxDdlData.SetData(null);
        this.showloader = true;
  
        // Call the API using fetch
        this.vdxData
          .LoadRequiredMasterDataForFilters(0, 'custom', mockCriteria, false, 'custom', 3)
          .then((res) => {
            this.showloader = false;
            this.vdxDdlData?.SetData(res.data);
          })
          .catch((error) => {
            console.error('Error loading master data:', error);
            this.showloader = false;
          });
      })
      .catch((error) => {
        console.error('Error loading criteria JSON:', error);
      });
  }
  

  publishSlaveFilterData(objs: Array<UIListItem>) {
    this._filtersAndData = objs.map((o: UIListItem) => new FilterTypeDefintion(o.name, o.value));
    this.propagateValueToSchedules();
  }

  propagateValueToSchedules() {
    //want this to be run in synchronouse mode, so for(i) looping and not forEach.
    for (let i = 0; i < this._schedules.length; i++) {
      this._schedules[i].reportName =  this._selectedReportForSave.title;
      this._schedules[i].reportColumns = this._selectedReportForSave.slavecolumn;
    }
  }

  onReceivedFilterSelection(objs: Array<UIListItem>) {
    this._selectedReportForSave.criteria =this._helper.convertUiListToCsv(objs, "value");
    this._selectedReportForSave.criteriaDisplayNames = this._helper.convertUiListToCsv(objs, "name");
    this.publishSlaveFilterData(objs);
  }

  onValueSelected(event: any): void {
    this.selectedValue = event.target.value;
  }

  validateDaysInput(event: any) {
    let value = parseInt(event.target.value, 10);
    if (isNaN(value)) {
      this._days = this._min; 
    } else if (value < this._min) {
      this._days = this._min;
    } else if (value > this._max) {
      this._days = this._max; 
    } else {
      this._days = value;
    }
    this.reportForm.get('MaxDays')?.setValue(this._days, { emitEvent: false });
    event.target.value = this._days.toString();
  }

  onFileSelectedToUpload(e: any) {
    const file = e.target.files[0];
    if (file) {
      const allowedExtensions = ['xls', 'xlsx'];
      const fileExtension = file.name.split('.').pop().toLowerCase();
  
      if (allowedExtensions.includes(fileExtension)) {
        this._templateFilesSelectedToUpload = file;
      } else {
        this.msgbox.error("Please upload a valid Excel file (.xls or .xlsx)", "");
        e.target.value = '';
      }
    }
  }

  IsAllInputValid(): boolean {
    if (this.utils.isEmptyOrSpaces(this.reportForm.get('Title').value)) {
      this.toastr.error("Report title is mandatory");
      return false;
    }
    if (this.utils.isEmptyOrSpaces(this.reportForm.get('Description').value)) {
      this.toastr.error("Report description is mandatory");
      return false;
    }
    if (this.utils.isEmptyOrSpaces(this.reportForm.get('spname').value)) {
      this.toastr.error("SP Name is mandatory");
      return false;
    }
    if (this.utils.isEmptyOrSpaces(this.reportForm.get('namedrange').value)) {
      this.toastr.error("Named Range is mandatory");
      return false;
    }
    if (this.utils.isEmptyOrSpaces(this.selectedValue)) {
      this.toastr.error("Version is mandatory");
      return false;
    }
    // if (this.utils.isEmptyOrSpaces(this._templateFilesSelectedToUpload.name)){
    //   this.toastr.error("Template file is mandatory");
    // }
    if (this.utils.isEmptyOrSpaces(this._selectedReportForSave.category)) {
      this.toastr.error("Category is mandatory");
      return false;
    }
    if (this.utils.isEmptyOrSpaces(this._selectedReportForSave.criteria)) {
      this.toastr.error("At least one Filter Criteria is mandatory");
      return false;
    }
    if (!this.reportForm.get('ismaster').value && this.utils.isEmptyOrSpaces(this._selectedReportForSave.roles)) {
      this.toastr.error("At least one Role is mandatory");
      return false;
    }
    if (this.utils.isEmptyOrSpaces(this.reportForm.get('Columns').value)) {
      this.toastr.error("Column is mandatory");
      return false;
    }
    return true;
  }

  Save(actionNumber: number,ismaster:boolean=false): void {
    if (this.IsAllInputValid()) {
      if (this._templateFilesSelectedToUpload) {
        this._selectedReportForSave.templateFile = this._templateFilesSelectedToUpload.name;
      
        this.rdb.UploadTemplate(this._templateFilesSelectedToUpload)
          .then((uploadRes) => {
            if (!uploadRes==true) {
              this.toastr.error("Template file upload failed");
              return;
            }            
            if (this.templateFileUploader) {
              this.templateFileUploader.nativeElement.value = "";
            }
          })
          .catch((err) => this.appHelper.HandleError(err));
        }
        else{
          this._selectedReportForSave.templateFile ='';
        }     

      this._selectedReportForSave.isMaster = ismaster;
      this._selectedReportForSave.title = this.reportForm.get('Title').value;
      this._selectedReportForSave.description = this.reportForm.get('Description').value;
      this._selectedReportForSave.storedProcedure = this.reportForm.get('spname').value;
      this._selectedReportForSave.namedRange = this.reportForm.get('namedrange').value;
      this._selectedReportForSave.parameterNames = this.reportForm.get('parametername').value;     
      this._selectedReportForSave.isactive = this.reportForm.get('isactive').value;   
      this._selectedReportForSave.isOrganization = this.reportForm.get('isOrganization').value;
      this._selectedReportForSave.isAsynchronousReport = !!this.reportForm.get('isasynchronous').value; 
      this._selectedReportForSave.isMultisheet = !!this.reportForm.get('ismultisheet').value; 
      this._selectedReportForSave.columns = this.reportForm.get('Columns').value;
      this._selectedReportForSave.version = this.selectedValue;
      this._selectedReportForSave.maxDays = this._days.toString();
      this._selectedReportForSave.connectionName = this.reportForm.get('connectionname').value || '';

      this._selectedReportForSave.report = "value"; 
      this._selectedReportForSave.action = actionNumber.toString();
  
      this.rdb.UpdateCustomReport(this._selectedReportForSave)
        .then((updateRes) => {
          if (updateRes.data) {
            this._selectedReport = updateRes.data[0];
            this._selectedReport.action = this._selectedReportForSave.action;
            this._selectedReport.connectionName = this._selectedReportForSave.connectionName;
            this._selectedReport.notifyOnlyOnData = false;
            this._selectedReport.NotifyBlankAttachment = false;

            let schedulesToSave = this.utils.clone(this._schedules);
            this.rdb.BuildDatesStoreForScheduler(this._selectedReport, schedulesToSave);

            this.rdb.UpdateSchedules(schedulesToSave)
            .then((scheduleRes) => {
              if (scheduleRes.data) {
                let savedEntries: RBUserscheduledReportMaster[] = scheduleRes.data;

                if (this._deletedScheduleJobIds.length > 0) {
                  this.rdb.RemoveScheduledJobs(this._deletedScheduleJobIds.join(","));
                }

                this._schedules = this.rdb.BuildDatesStoreForUI(savedEntries);
                this.toastr.success("Saved successfully!");
              
                this.router.navigate(['/']);
              }
            })

          }
      });
    }
  }

  addNewSchedule() {
    let newSchedule = new RBUserscheduledReportMaster();
    newSchedule.isActive = true;
    newSchedule.cronExpression = "0 0 0/1 1/1 * ? *";
    newSchedule.notifyOnlyOnData = false;
    this._schedules.push(newSchedule);
  }

  removeSchedule(index: number, jobId: number, reportId: number, isaction: string) {
    const dialogData: DialogData = {
      title: 'Confirm Deletion',
      message: 'Are you sure you want to delete this schedule?',
      confirmButtonText: 'Delete',
      cancelButtonText: 'Cancel'
    };
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: dialogData
    });
  
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._deletedScheduleJobIds.push(this._schedules[index].jobId);
        this._schedules.splice(index, 1);
        let action: number = 0;
        action = isaction === 'Publish' ? 1 : isaction === 'Draft' ? 2 : 0;

        this.rdb.DeleteScheduledJobs(jobId, action).then(
          (response: AxiosResponse<any>) => {
            if (response.status === 200) {
              this.msgbox.error('Schedule Deleted Successfully!', '');
            } else {
              console.log('Schedule deletion failed');
            }
          }
        );
      } else {
        console.log('User canceled the deletion.');
      }
    });
  }


  OnReturnClick(){
    this.router.navigate(['/']);
  }

}
