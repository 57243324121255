import { Component, OnInit } from '@angular/core';
import { allReports } from 'src/app/helpers/app-models';
import { RbDataService } from 'src/app/services/rb-data.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NewReportComponent } from '../main-new-report/new-report/new-report.component';
import { MenuItem } from 'primeng/api';
import { SsoEmmAuthService } from 'src/app/helpers/sso-emm-auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  selectedUserdat: any;
  showDetails: boolean = false;
  searchText: string = "";
  filter: string = 'All';
  selectedMenu: string = '';
  allReports: allReports[] = [];
  distinctMenus: string[] = [];
  showloader: boolean = true;
  items: MenuItem[];
  
  allCount: number = 0;
  publishedCount: number = 0;
  unpublishedCount:number=0;
  draftCount: number = 0;
  noSchedulesCount: number = 0;
  issuperadmin:boolean;
  productName: string = ''; 

  constructor(private router: Router, 
    private ssoEmm: SsoEmmAuthService,
    private rdb: RbDataService, 
    public dialog: MatDialog) {}

  AllReports(): void {
    this.rdb.GetAllReports().then(
      (res: any) => {
        this.allReports = res.data;
        this.showloader = false;
        this.distinctMenus = [...new Set(this.allReports.map(report => report.menu))];
        this.computeCounts();
      },
      (error: any) => {
        console.error('Error fetching reports:', error);
      }
    );
  }

  ngOnInit(): void {
    this.items = [
      { label: 'Statistics', routerLink: '/' },
    ];
    this.isProduct();
    this.AllReports();
  }

  isProduct(): void {
    this.ssoEmm.CheckSession().then((res: any) => {
      this.productName = res.data?.productName;
      if (this.productName === "VitalAdmin") {
        this.issuperadmin = true;
      } else {
        this.issuperadmin = false;
      }
    });
  }
  

  computeCounts(): void {
    const filteredReports = this.selectedMenu 
      ? this.allReports.filter(report => report.menu === this.selectedMenu)
      : this.allReports;
  
    this.allCount = filteredReports.length;
    this.publishedCount = filteredReports.filter(report => report.published && report.isActive).length;
    this.unpublishedCount = filteredReports.filter(report => report.published && !report.isActive).length;
    this.draftCount = filteredReports.filter(report => !report.published).length;
    this.noSchedulesCount = filteredReports.filter(report => this.hasNoSchedules(report.schedules)).length;
  }

  hasNoSchedules(schedules: string): boolean {
    // Extract the first value before the parenthesis
    const firstValue = schedules.split(' ')[0];
    // Return true if the first value is '0'
    return firstValue === '0';
  }

  clearSearch() {
    this.searchText = "";
  }

  cardSelect(event: any) {
    this.showDetails = true;
    this.selectedUserdat = event;
  }

  closeUserDetails(event: any) {
    this.showDetails = event;
  }

  navigateToNewReport(): void {
    const dialogRef = this.dialog.open(NewReportComponent, {
      width: '100vw',
      height:'80vh',
      data: {},
      disableClose: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result && result.event === 'Created') {
        this.AllReports(); 
      }
    });
  }

  setFilter(filter: string): void {
    this.filter = filter;
  }

  onMenuChange(menu: string): void {
    this.selectedMenu = menu;
    this.computeCounts(); 
  }
  

  navigateToCustomReport(){
    this.router.navigate(['/custom-report']);
  }
}
