import { Component, EventEmitter, Input, Output } from '@angular/core';
import { VdxDropdownsDataManagerService } from '../../vdx-helpers/vdx-dropdowns-data-manager.service';
import { ConfigUtils } from 'src/app/helpers/config-utils';

@Component({
  selector: 'vr-vdx-labs-dropdown',
  templateUrl: './vdx-labs-dropdown.component.html',
  styleUrls: ['./vdx-labs-dropdown.component.scss']
})
export class VdxLabsDropdownComponent {
  KEY = "accountID";

  lists = new Array<any>();
  selectedObjects = new Map<string, any>();
  listsCounts = new Map<string, number>();

  labName: string = "";
  userLoggedInLabName = this.utils.GetLoggedInOrganizationName();

  constructor(
    private vdxDdlManager: VdxDropdownsDataManagerService,
    private utils: ConfigUtils
  ) {
    this.labName = this.utils.GetLoggedInOrganizationName();
  }
  @Input() SelectedValues: string = "";
  @Input() set refreshData(val: boolean) {
    if (val) this.prepareData();
  }
  @Input() selectAll: boolean = false;
  @Output() onSelected = new EventEmitter<string>();
  @Output() onSelectedLabels = new EventEmitter<string>();
  @Output() onAllSelectedStatus = new EventEmitter<boolean>();

  prepareData() {
     
    let list = this.vdxDdlManager.GetLaboratories();
     
    if (list) {
      let lookup = new Map<string, any>();
      let lk = "";
      for (let i = 0; i < list.length; i++) {
        lk = list[i]["organizationName"];
        if (lookup.has(lk)) lookup.get(lk).push(list[i]);
        else lookup.set(lk, [list[i]]);
      } 
      let final = new Array<any>();
      for (let [key, value] of lookup) {
        final.push({ displayName: key, options: value });
        this.listsCounts.set(key, value.length);
      }

      this.lists = [...final];
      
    }
  }

  onSelectedItems(orgname: string, e: []) { 
    this.selectedObjects.set(orgname, e);
    this.raiseOnSelectedEvent();

    let allSelected = true;
    for (let [key, value] of this.selectedObjects) {
      console.log(this.listsCounts.get(key) + " !== " + value.length);
      if (this.listsCounts.get(key) !== value.length) {
        allSelected = false;
        this.onAllSelectedStatus.emit(false);
        return;
      }
    }

    if (allSelected === true) this.onAllSelectedStatus.emit(true);

    //console.log("All Selected:", allSelected);
  }

  raiseOnSelectedEvent() {
    let joined = new Array<any>();
    for (let [key, value] of this.selectedObjects) {
      if (value && value.length > 0) joined = [...joined, ...value];
    }
    console.log(joined);
    this.onSelected.emit(joined.map((f: any) => f[this.KEY]).join(","));
    this.onSelectedLabels.emit(joined.map((f: any) => f.displayName).join(","));
  }

}
