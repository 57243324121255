import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ControlContainer, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-toggle',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class ToggleComponent implements OnInit, OnChanges {

  @Input() isChecked: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() id: '' | 'secondary' | 'secondary-primary' | 'admin-primary' = '';
  @Input() formControlName: string | null = null;
  @Output() clicked = new EventEmitter<boolean>();
  @Input() labelActiveText: string = 'Active';
  @Input() labelInactiveText: string = 'Inactive';

  _checkedStatus: boolean = false;

  constructor(private _fb: FormGroupDirective) {}

  ngOnInit(): void {
    this._checkedStatus = this.isChecked;

    if (this.formControlName) {
      const control = this._fb.control.get(this.formControlName);
      if (control) {
        this._checkedStatus = control.value;
        control.valueChanges.subscribe((res) => {
          this._checkedStatus = res;
        });

        if (this.isDisabled) {
          control.disable();
        } else {
          control.enable();
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this._checkedStatus = this.isChecked;

    if (this.formControlName) {
      const control = this._fb.control.get(this.formControlName);
      if (control && changes['isDisabled']) {
        if (this.isDisabled) {
          control.disable();
        } else {
          control.enable();
        }
      }
    }
  }

  emitValue(e: any): void {
    this._checkedStatus = e.target.checked;
    this.clicked.emit(this._checkedStatus);
  }
}
