import { Component, EventEmitter, Input, Output, ViewChild, AfterViewInit } from '@angular/core';
import { MatTabGroup } from '@angular/material/tabs';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements AfterViewInit {
  @Output() selectedTab = new EventEmitter<string>();
  @Input() tabData: { Htext: string }[] = [];
  @Input() activeTab: string; // This will be set from the parent component

  @ViewChild(MatTabGroup) tabGroup?: MatTabGroup;

  ngAfterViewInit(): void {
    // Ensure the tab group is initialized after view is rendered
    this.setActiveTab();
  }

  ngOnChanges(): void {
    // Set the active tab when input changes
    if (this.tabGroup) {
      this.setActiveTab();
    }
  }

  private setActiveTab(): void {
    if (this.tabData.length > 0 && this.tabGroup) {
      const index = this.tabData.findIndex(tab => tab.Htext === this.activeTab);
      if (index !== -1) {
        this.tabGroup.selectedIndex = index;
      }
    }
  }

  onTabChanged(event: any): void {
    if (this.tabData.length > 0) {
      this.selectedTab.emit(this.tabData[event.index].Htext);
    }
  }
}
