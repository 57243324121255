import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bootstrap-icon',
  templateUrl: './bootstrap-icon.component.html',
  styleUrls: ['./bootstrap-icon.component.scss']
})
export class BootstrapIconComponent {
  _iconName: string = '';
  _css: string = '';

  styleCss: string = '';
  styleInline: string = '';
  styleOutline: string = '';  // Add outline style

  @Input() set IconName(ic: string) {
    this._iconName = ic;
    this.updateStyle();
  }

  @Input() set IconSize(nu: number) {
    this.styleInline = "line-height:0; font-size:" + nu + 'px';
  }

  @Input() set cssStyle(css: string) {
    this._css = css;
    this.updateStyle();
  }

  @Input() set iconOutline(outline: string) {  // Add outline input
    this.styleOutline = outline;
    this.updateStyle();
  }

  updateStyle() {
    this.styleCss = `${this._css} bi bi-${this._iconName}`;
    this.styleInline += `; ${this.styleOutline}`;  // Apply outline style
  }

  ngOnInit(): void {}
}
