import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { UserDetails } from './app-models';


@Injectable({
  providedIn: 'root'
})
export class BrowserStorageManagerService {

  private DOMAIN_KEY = 'domainKey';
  private USER_INFO = 'userInfo';
  private userInfoDataCache = new UserDetails();
  private userDomainKey : string = '';

  constructor(
    private cookieSvc: CookieService
  ) { }

  GetEMMCookiesForHeader() {
    return {
      "Content-Type": 'application/json',
      "x-va-senderagent-id": this.cookieSvc.get('senderAgentID'),
      "x-va-hash": this.cookieSvc.get('hashKey'),
      "x-va-transaction-id": this.cookieSvc.get('transactionID'),
      "Authorization": this.cookieSvc.get('SSOAppTokenString'),
      "x-va-tokenurl": this.cookieSvc.get('tokenURL')
    }
  }

  GetCookiesForHeaderForSVC() {
    return {
      "Content-Type": 'application/json',
      "x-va-senderagent-id": this.cookieSvc.get('senderAgentID'),
      "x-va-hash": this.cookieSvc.get('hashKey'),
      "x-va-transaction-id": this.cookieSvc.get('transactionID'),
      "Authorization": this.cookieSvc.get('SSOAppTokenString'),
      "x-va-tokenurl": this.cookieSvc.get('tokenURL'),
      "x-va-ip":this.cookieSvc.get('ipAddress')
    }
  }

  GetEMMNonHeaderValues() {
    return {
      "ip": this.cookieSvc.get('ipAddress'),
      "authUrl":this.cookieSvc.get('tokenURL')
    };
  }
 
  GetDeploymentKey():string{
    return localStorage.getItem('dev_deployment_key')!; 
  }

  GetRoleName():string{
    return localStorage.getItem('dev_role_name')!; 
  }
  
  
  GetOrgId():number{
  return +localStorage.getItem('dev_org_id')!; 
  }
  
  GetAPIHeaders():{}{ 
      return { Authorization : localStorage.getItem('Authorization') }; 
  } 

  Getorganization(){
    return environment.dev_org_id;
  }

  SaveUserInfo(info:any){ 
    // environment.dev_deployment_key = this.cookieSvc.get('domainName');  
    // environment.dev_auth.Authorization = this.cookieSvc.get('SSOAppTokenString');
    // environment.dev_org_id = JSON.parse(info["userinfo"]).organization
    // console.log(environment.dev_org_id);
    localStorage.setItem('dev_deployment_key', this.cookieSvc.get('domainName'));
    localStorage.setItem('Authorization', this.cookieSvc.get('SSOAppTokenString'));
    localStorage.setItem('dev_org_id', JSON.parse(info["userinfo"]).organization);
    localStorage.setItem('dev_role_name', JSON.parse(info["role"]).organization);

    // console.log(localStorage.getItem('dev_deployment_key'));
    // console.log(localStorage.getItem('Authorization'));
    // console.log(localStorage.getItem('clickCounter'));
  }

  GetUserInfo(){
    return this.userInfoDataCache;
  }
 

  ClearCookies() {
    this.cookieSvc.deleteAll();
  }

}
