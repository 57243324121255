import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-numberic-input-control',
  templateUrl: './numberic-input-control.component.html',
  styleUrls: ['./numberic-input-control.component.scss']
})
export class NumbericInputControlComponent {
  @Input() max: number = 100;
  @Input() min: number = 0;
  @Input() width: number = 65;

  _number: number = 0;
  @Input() set initialValue (val:any){
    this._number = val;
  }

  @Output() onChange = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {}

  onReceivedNumber() {
    if (this._number) {
      this._number = this._number > this.max ? this.max : this._number;
      this._number = this._number < this.min ? this.min : this._number;

      this.onChange.emit(this._number);
    } else {
      this.onChange.emit(-1);
    }
  }

}
