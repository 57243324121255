<p-dropdown
  [options]="list"
  [optionLabel]="itemValue"
  [optionValue]="itemKey"
  (onChange)="onValueSelected($event)"
  placeholder="Select"
  [filter]="true"
  [filterBy]="itemValue"
  styleClass="p-info-dd form-select-sm"
  [(ngModel)]="selectedValue"
  appendTo="body"
>
</p-dropdown>
