import { environment } from "src/environments/environment";

export enum ReportFileType {
    PDF = 'PDF',
    XL = 'XLSX'
}

//export const API_BASE_URL_V2 = environment.api_url_base_url+"/api/v2";
export const API_BASE_URL_V1 = environment.statsUrlBase+"/api/v1";

export const DATE_FORMAT_INPUT = 'yyyy-MM-dd'
export const DATE_FORMAT_SAVE_INPUT = 'MM/dd/yyy'
export const DATETIME_VIEW_FORMAT = 'MM/dd/yyy h:mm:ss a'

export const NO_CRITERIA_INFO_MESSAGE = "Please select report filter criteria(s)"
export const BUTTON_DOWNLOAD_TITLE = "Download Report";
export const MESSAGE_DOWNLOAD_COMPLETE ='DOWNLOAD_DONE';
export const MESSAGE_STOP_ASYNC_CHECK ='MESSAGE_STOP_ASYNC_CHECK';
export const MESSAGE_FAVORITE_UPDATED = 'FAV_STATUS_UPDATED';

export enum CustomReports {
    FAVORITES = 'Favorites',
    RECENT_REPORTS = 'Recent Reports'
}

export const QAI_ADDRESS = 'QAI'

export enum FilterType {
    DATES_DOS = 1,
    DATES_SIGNOUT,
    DATES_WEEK_OF_DATE,
    DATES_PAYMENT_POST_DATE,
    DATES_DEPOSITED_DATE,
    DATES_APPEALED_DATE,
    DATES_DENIAL_DATE,
    DATES_CALENDAR_DATE,
    DATES_CALL_DATE,
    DATES_CLAIM_CREATION,

    PAYER,
    ORDERING_FACILITY,
    CASE_TYPE,
    FACILITY_ORG_NAME,
    FACILITY_STATES,
    SIGNED_PATHO,
    SIGNED_BY_LOCATION,
    CLIENT_ID,
    CPT_CODES,
    CPT_CODES_TEXTBOX,
    CLIENT_BILL_STATUS,
    CPT_CODE_MODIFIERS,
    PROCESS,
    ORGANIZATIONS,
    CALL_USERS
}


export enum ReportFilterType {
    DATES_DOS = 'servicedate',
    DATES_SIGNOUT = 'signoutdate',
    DATES_WEEK_OF_DATE = 'weekoffdate',
    DATES_PAYMENT_POST_DATE = 'paymentposteddate',
    DATES_DEPOSITED_DATE = 'paiddate',
    DATES_APPEALED_DATE = 'appealeddate',
    DATES_DENIAL_DATE = 'denialdate',
    DATES_CALENDAR_DATE = 'calendardate',
    DATES_CALL_DATE = 'calldate',
    DATES_CLAIM_CREATION = 'claimcreationdate',
    DATES_SENT_DATE= 'sentdate',

    Organizationid = 'organizationid',

    PAYER = 'payer',
    ORDERING_FACILITY = 'facility',
    CASE_TYPE = 'casetype',
    FACILITY_ORG_NAME = 'facilityorg',
    FACILITY_STATES = 'facilitystate',
    SIGNED_PATHO = 'signedpathologist',
    SIGNED_BY_LOCATION = 'signedbylocation',
    CLIENT_ID = 'externalclientid',
    CPT_CODES = 'cptcodes',
    CPT_CODES_TEXTBOX = 'cpt',
    CLIENT_BILL_STATUS ='clientbillstatus',
    CPT_CODE_MODIFIERS = 'cptcodemodifier',
    PROCESS ='~~',
    ORGANIZATIONS = '~~',
    CALL_USERS='calluserids',
    CLAIM_OR_ACCESSION = 'claimoraccession',
    FREE_TEXT_FOR_CLAIM_OR_ACCESSION = 'freetextforclaimoraccession',
    REPORT_FIELDS = 'ReportFields'
}

export enum VDXReportFilterTypes {
    DATES_SIGNOUT = 'signoutdate',
    DATES_COLLECTION = 'collectiondate',
    DATES_RECEIVED = 'receiveddate',


    SERVCIE_TYPE = 'servicetype',
    CYTO_TECHNO_TYPE = 'cytotechnologist',
    LABORATORY = 'laboratory',
    ORDERING_ORGANIZATION = 'orderingorganization',
    SALESEXECUTIVE = 'salesexecutive',
    REPORT_TYPE = 'reporttype',
    CASE_TYPE = "casetype",
    PERCENTAGE = "percentage",
    PATHOLOGISTS = "pathologist",
    DIAGNOSTICIAN= 'diagnostician',
    SCREENTECHNOLOGIST= "screeningtechnologist",
    FLAGS= "flags",
    SERVICE_LIST = 'servicelist',
    SERVICE_LIST_DS = 'servicelistds',
    INCLUDE_SUMMARY = 'includesummary',
    INTERNAL_VS_SENTOUT = 'internalvssentout',
    ACCESSIONED_BY = 'accessionedby',
    ACTION_PERFORMED = 'actionperformed',
    PERFORMED_BY = 'performedby',
    
    ALGORITHM_TYPE = 'algorithmtype',

    START_DATE = 'startdate',
    END_DATE = 'enddate',
    
    CASE_TYPE_CATEGORY = 'casetypecategory',
    CASE_TYPE_LIST = 'casetypelist',
    SORTBY_TAT = 'sortbytat',
    ASC_DESC = 'ascdes',
    DIAGNOSISPATHOLOGIST = 'diagnosispathologist',
    DURATION_PC = 'durationpc',
    DURATION_QA = 'durationqa',
    DURATION = 'duration',
    DURATION_CPT = 'durationcpt',
    DURATION_DS='durationds',
    DURATION_OMR='durationomr',
    DURATION_TAT = 'durationtat',
    DURATION_OR = 'durationor',
    SPECIMEN_TYPE = 'specimentype',
    REPEATED_BY_OMR='repeatedbyomr',
    INCLUDE_OR ='includeor',
    INCLUDE = 'include',
    TECHNICAL_STATES = 'technicalstates',
    ACCOUNT_CASE_TYPE = 'accountcasetypelist',
    PROFESSIONAL_STATES = 'professionalstates',
    SERVICE_LIST_PC = 'servicelistpc',
    SERVICE_LIST_OMR= 'servicelistomr',
    SERVICE_LIST_TAT = 'servicelisttat',
    PRIORITY = 'priority',
    GROUP_BY_PC = 'groupbypc',
    GROUP_BY_CPT = 'groupbycpt',
    GROUP_BY_OMR = 'groupbyomr',
    GROUP_BY_QA = 'groupbyqa',
    GROUP_BY_TAT ='groupbytat',
    GROUP_BY = 'groupby',
    SORT_BY = 'sortby',
    SORTBY_TAT_ASCDES = 'sortbytat:ascdes',
    FETCH_INDICATOR_TYPE = 'fetchqaindicatortypes',
    REPORTED_BY_QA = 'repeatedbyqa',
    REPORTED_BY = 'reportby',
    REPORT_TAT= 'reporttat',
    ORDER_BY = 'orderby',
    REPEATED_BY = 'repeatedby',
    YEAR = 'year',
    QUARTER = 'quarter',
    READING_LOCATION = 'readinglocation',
    CASE_STATUS_LIST = "casestatuslist",
    CATEGORY="category",
    HemOnc_SubCaseType="hemoncsubcasetype",
    TEST_STATUS="teststatus" ,
    TEST_TYPE="testtype",
    TIME_ZONE="timezone",
    CASE_STATUS="casestatus",
    MESSAGE_TYPE="messagetype",
    DIRECTION="direction",
    STATUS="status"
}

export enum VDXPayloadKeys {
   // UserId="UserId",
    ReportId="ReportId",
    OrganizationID="OrganizationID",
    Laboratory="Laboratory",
    RunBy="RunBy",
    LaboratoryList="LaboratoryList",
    OrderingOrganizationList="OrderingOrganizationList",
    OrderingFacilityList="OrderingFacilityList",
    ServiceType="ServiceType",
    CaseTypeCategory="CaseTypeCategory",
    SelectedCriterias="SelectedCriterias",
    HemOncSubCaseType="HemOncSubCaseType",
    TestStatus="TestStatus",
    TestType="TestType",
    AccessionNumberFrom="AccessionNumberFrom",
    AccessionNumberTo="AccessionNumberTo",
    CaseTypeList="CaseTypeList",
    CaseStatusList="CaseStatusList",
    ScreenTechnologist="ScreenTechnologist",
    DiagnosisPathologist="DiagnosisPathologist",
    Flags="Flags",
    StoredProcedure="StoredProcedure",
    Criteria="Criteria",
    TemplateFile="TemplateFile",
    NamedRange="NamedRange",
    Category="Category",
    Title="Title",
    ReportType="ReportType",
    version="version",
    _paramsToExcel="_paramsToExcel",
    actualRepName="actualRepName",
    AttributesName="AttributesName",
    PerformedBy="PerformedBy",
    ActionPerformed="ActionPerformed",
    Diagnostician="Diagnostician",
    CustomServicetype="CustomServicetype",
    Algorithmtype="Algorithmtype",
    Percentage="Percentage",
    ReadingLocations="ReadingLocations",
    TimeZone="TimeZone",
    Casetypes="Casetypes",
    DeploymentKey="DeploymentKey",
    Dates="Dates"
}

export const ReportBuilderToStatsCriteriaMap = new Map<string,string>([
    ["ReceivedDateFrom",VDXReportFilterTypes.DATES_RECEIVED],
    ["ReceivedDateTo",VDXReportFilterTypes.DATES_RECEIVED],
    ["CollectionDateFrom",VDXReportFilterTypes.DATES_COLLECTION],
    ["CollectionDateTo",VDXReportFilterTypes.DATES_COLLECTION],
    ["SignoutDateFrom",VDXReportFilterTypes.DATES_SIGNOUT],
    ["SignoutDateTo",VDXReportFilterTypes.DATES_SIGNOUT],
    ["LaboratoryList",VDXReportFilterTypes.LABORATORY],
    ["OrderingOrganization",VDXReportFilterTypes.ORDERING_ORGANIZATION],
    ["Salesexecutive",VDXReportFilterTypes.SALESEXECUTIVE],
    ["ServiceType",VDXReportFilterTypes.SERVCIE_TYPE],
    ["CaseTypeCategory",VDXReportFilterTypes.CASE_TYPE_CATEGORY],
    ["CaseTypeList",VDXReportFilterTypes.CASE_TYPE_LIST],
    ["ScreeningTechnologist",VDXReportFilterTypes.SCREENTECHNOLOGIST],
    ["DiagnosisPathologist",VDXReportFilterTypes.DIAGNOSISPATHOLOGIST],
    ["CaseFlags",VDXReportFilterTypes.FLAGS],
    
    ["AccessionNumberFrom","AccessionNumberFrom"],
    ["AccessionNumberTo","AccessionNumberTo"],
    //["UserID","UserID"],

    ["OrderingFacility","OrderingFacility"],
    ["HemOncSubCaseType","hemoncsubcasetype"],
    ["TestStatus","TestStatus"],
    ["TestType","TestType"],
    ["CaseStatusList",VDXReportFilterTypes.CASE_STATUS_LIST],
    
    ["DeletedSequesteredDateFrom","DeletedSequesteredDate"],
    ["DeletedSequesteredDateTo","DeletedSequesteredDate"],
    ["OrderedDateFrom","OrderedDate"],
    ["OrderedDateTo","OrderedDate"],
]);