
<div>
<div style="font-family: 700;margin-bottom: -1vh;margin-left: 1vw;">
  <!-- <h3 mat-dialog-title >Sample Report</h3> -->
  <h3 mat-dialog-title >Preview Report</h3>

  <!-- <span (click)="close()" style="cursor: pointer;">
    <mat-icon>close</mat-icon>
  </span> -->
</div>
<!-- Loading Spinner -->
<div *ngIf="isLoading" class="loading-spinner" style="width: 60vw;">
  <mat-spinner  diameter="20" mode="indeterminate"></mat-spinner>
</div>

<!-- Table Content -->
<div *ngIf="!isLoading" mat-dialog-content>
  <table *ngIf="previewList && previewList.length > 0" class="styled-table">
    <thead>
      <tr>
        <th *ngFor="let key of getKeys(previewList[0].data)">{{ key }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let report of previewList">
        <td *ngFor="let key of getKeys(report.data)">{{ report.data[key] }}</td>

      </tr>
    </tbody>
  </table>
  <p *ngIf="previewList.length === 0">No data available.</p>
</div>

<div mat-dialog-actions >
  <button mat-button (click)="close()" >Close</button>
</div>
</div>
