<p-overlayPanel #op styleClass="dropdown-pan-width bg-primary-dark">
    <app-multiselect-search-control
      [options]="listData"
      [selectSome]="selectSome"
      [keyName]="listItemKeyName"
      [valueName]="listItemLabelName"
      [textDimKeyName]="itemTextDimKeyName"
      (selectedObjects)="onSelectedObjectsReceived($event)"
      [selectAll]="isSelectAll"
      [showClearAll]="false"
      [headerTitle]="listDataHeader"
    ></app-multiselect-search-control>
  </p-overlayPanel>
  
  <div
    class="d-flex flex-row justify-content-between align-items-center gap-2 rounded"
    (click)="openPop($event)"
  >
    <div class="flex-grow-1 cursor-pointer align-items-baseline">
      <div
        class="d-flex flex-row justify-content-start align-items-center gap-2 cursor-pointer multiselect-list-item"
      >
        <div style="line-height: 0 !important;">
          <input
            class="chk-blue"
            type="checkbox"
            style="transform: scale(1.1) !important;"
            [checked]="isSelectAll"
            (click)="onCheckToggle($event)"
          />
        </div>
        <div
          style="
            font-size: 1rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          "
          [class]="checkBoxLabelCss"
        >
          {{checkBoxLabel}}
        </div>
        <div
          *ngIf="selectedObjectsStatsLabel !== ''"
          class="badge bg-primary-dark text-white px-2 py-1 fw-bold p-0"
          style="font-size: 0.8rem;"
        >
          {{selectedObjectsStatsLabel}}
        </div>
      </div>
    </div>
  </div>
  