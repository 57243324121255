import { Component, Input } from '@angular/core';
import { VdxBulkSelectorBaseComponent } from '../vdx-bulk-selector-base';

@Component({
  selector: 'vr-vdx-bulk-organization-selector',
  templateUrl: './vdx-bulk-organization-selector.component.html',
  styleUrls: ['./vdx-bulk-organization-selector.component.scss']
})
export class VdxBulkOrganizationSelectorComponent extends VdxBulkSelectorBaseComponent {

  
  @Input() selctionType : string = '';
  @Input() SelectedValues : string = '';

}
