import { Component, EventEmitter, Input, Output } from "@angular/core";
import * as vconsts from "../vdx-helpers/vdx-constants";

@Component({
  template: "",
  styles: [],
})
export class VdxBulkSelectorBaseComponent {
  _selectAllLabs!: boolean;
  _selectEventArg!: boolean;
  _selectionCopy: any;

  @Input() isDropdownValuesReady: boolean = false;
  @Input() label: string = "";
  @Output() onFilterValueChange = new EventEmitter<any>();
  @Output() onSelectedLabelsChange = new EventEmitter<any>();

  protected _vd_contst = vconsts;

  onSelectionChanged(e: any) {
    this._selectionCopy = e;
    this.onFilterValueChange.emit(
      this._selectAllLabs ? vconsts.All + "," + e : e
    );
  }

  onSelectionLabelChanged(e: any) {
    console.log('--selected name--',e,this._selectAllLabs);
    let names :string = e;
    this.onSelectedLabelsChange.emit(
      this._selectAllLabs ? (names.indexOf(',') > -1 ? vconsts.All : e) : e
    );
  }

  onUserSelectAll(e: any) {
    console.log('onUserSelection',e);
    this._selectAllLabs = e;
    this._selectEventArg = e;
    //this._selectEventArg = (this._selectAllLabs === true);
  }

  onAllSelectedStatusChagne(flag: boolean) {
    console.warn('chaning _selectAllLabs',flag)
    //if (flag === false && this._selectAllLabs === true){
      this._selectAllLabs = flag;
    //} 

    this.onFilterValueChange.emit(
      //flag ? vconsts.All + "," + this._selectionCopy : this._selectionCopy
      flag ? vconsts.All : this._selectionCopy
    );
  }

  onLabSelectedStatusChagne(flag: boolean) {
    console.warn('chaning _selectAllLabs',flag)
      this._selectAllLabs = flag;
    this.onFilterValueChange.emit(
     this._selectionCopy
    );
  }
}
