export class CronOptions {
    formInputClass?: string = '';
    formSelectClass?: string = '';
    formRadioClass?: string = '';
    formCheckboxClass?: string = ''; 

    defaultTime: string = '';

    hideMinutesTab: boolean = false;
    hideHourlyTab: boolean = false;
    hideDailyTab: boolean = false;
    hideWeeklyTab: boolean = false;
    hideMonthlyTab: boolean = false;
    hideYearlyTab: boolean = false;
    hideAdvancedTab: boolean = false;
    hideSpecificWeekDayTab: boolean = false;
    hideSpecificMonthWeekTab: boolean = false; 

    use24HourTime: boolean = false;
    hideSeconds: boolean = false;

    cronFlavor: string = 'quartz';
}
