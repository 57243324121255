import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-grid-view',
  templateUrl: './grid-view.component.html',
  styleUrls: ['./grid-view.component.scss']
})
export class GridViewComponent {

  @Input() reports: any[] = [];
  @Input() filter: string = 'All';
  @Input() searchText: string = '';
  @Input() selectedMenu: string = '';
  @Input() fromClientUser: boolean = false;
  @Input() fromGlobalSearch: boolean = false;
  viewType: string = 'small';
  @Output() selectedCard = new EventEmitter<any>();
  filteredReports: any[] = [];
  toolTipText: any;
  showloader:boolean=false

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['reports'] || changes['filter'] || changes['searchText'] || changes['selectedMenu']) {
      this.showloader=true
      this.filterReports();

    }
  }

  templateList = [{
    headers: [
      { Name: 'Report Name', type: 'input', key: 'reportName', colWidth: 'col-sm-3 pl-2 column-wrapper' , colStyle:'' },
      { Name: 'Category', type: 'input', key: 'menu', colWidth: 'col-sm-1 pl-0 column-wrapper', colStyle:'' },
      { Name: 'Schedules', type: 'input', key: 'schedules', colWidth: 'col-sm-4 pl-0 role-wrapper' , colStyle:''},
      { Name: 'Created Date', type: 'input', key: 'createdDate', colWidth: 'col-sm-2 pl-2 column-wrapper' , colStyle:'text-align: center;'},
      { Name: 'Published', type: 'boolean', key: 'published', colWidth: 'col-sm-1 pl-2 column-wrapper ' , colStyle:'text-align: center;'}
    ]
  }];

  filterReports(): void {
    this.filteredReports = this.reports.filter(report => {
        const matchesFilter = this.filter === 'All' ||
                              (this.filter === 'Published' && report.published && report.isActive) ||
                              (this.filter === 'Unpublished' && report.published && !report.isActive) ||
                              (this.filter === 'Draft' && !report.published) ||
                              (this.filter === 'No-Schedules' && this.hasNoSchedules(report.schedules));
        const matchesMenu = this.selectedMenu === '' || report.menu === this.selectedMenu;
        const matchesSearch = report.reportName.toLowerCase().includes(this.searchText.toLowerCase());
        return matchesFilter && matchesMenu && matchesSearch;
    });
      this.showloader=false
  }

  hasNoSchedules(schedules: string): boolean {
    const firstValue = schedules.split(' ')[0];
    return firstValue === '0';
  }


  displayedColumns(report: any, key: string): any {
    return report[key];
  }

  RedirectToView(value: any) {
    this.selectedCard.emit(value);
  }

  enableToolTip(e: HTMLElement, item: any = '', header: any = ''): void {
    let title: any;
    if (item && header) {
      title = this.displayedColumns(item, header.key); // Make sure to pass header.key
    }
    this.toolTipText = e.scrollWidth > e.clientWidth ? title : '';
  }

  onMouseEnter(event: MouseEvent, item: any, header: any) {
    const target = event.target as HTMLElement;
    if (target.scrollWidth > target.clientWidth) {
      this.toolTipText = this.displayedColumns(item, header.key);
    } else {
      this.toolTipText = '';
    }
  }

}


