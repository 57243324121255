import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UIListItem } from 'src/app/helpers/app-models';

@Component({
  selector: 'app-cols-order',
  templateUrl: './cols-order.component.html',
  styleUrls: ['./cols-order.component.scss']
})
export class ColsOrderComponent {
  constructor() {
    this.cols = [
      {field:'id', header: 'Item ID'},
      { field: 'name', header: 'Selected Columns' },
    ];
  }

  cols: any[];
  @Input() data = new Array<UIListItem>();
  @Output() onDataChange = new EventEmitter<Array<UIListItem>>();

  onRemove(id:string){
    console.log(this.data);
    console.log('removing',id);
    let index = this.data.findIndex((f:UIListItem)=>f.id == id);
    this.data.splice(index,1);
    this.onDataChange.emit(this.data);
  }

  onRowReordered(){
    console.log('re-orderd',this.data);
    this.onDataChange.emit(this.data);
  }

}
